import { MedicalServicesRounded } from "@mui/icons-material";
import React from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

const vitals = ["75 BPM", "98.6 F", "145 Mg/Dl", "65 Kg"];
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function Vitals() {
  return (
    <div className="flex flex-col border gap-2 border-borderGray rounded-lg p-4 w-full">
      <p className="text-lg font-semibold">Vital Records</p>
      <div className="flex flex-wrap justify-between gap-4">
        {vitals?.map((vital, index) => {
          return (
            <div
              key={index}
              className="flex flex-row gap-2 items-center text-sm sm:text-base"
            >
              <MedicalServicesRounded className="text-primaryGreen" />
              <p>{vital}</p>
            </div>
          );
        })}
      </div>

      <div className="w-full" style={{ height: "200px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient
                id="primaryGreenGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor="#2F887C" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#ffffff" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#2F887C"
              strokeWidth={3}
              fill="url(#primaryGreenGradient)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
