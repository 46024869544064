import { getUserId } from "../middleware/authUtils";
import { apiClient } from "./api.client";

export const fetchDashboardData = async (req, res) => {
  const id = getUserId();
  try {
    const response = await apiClient.get(
      `${process.env.REACT_APP_BASE_URL}/dashboard/${id}`
    );
    return response.data;
  } catch (error) {
    return { status: "error", message: error.message };
  }
};