import React from "react";
import { Radar, Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, RadarChart, ResponsiveContainer } from "recharts";

const data = [
  { subject: "Fever", A: 120, B: 110, fullMark: 150 },
  { subject: "Cough", A: 130, B: 98, fullMark: 150 },
  { subject: "Cold", A: 130, B: 86, fullMark: 150 },
  { subject: "Heart", A: 90, B: 80, fullMark: 150 },
  { subject: "Back Pain", A: 88, B: 85, fullMark: 150 },
  { subject: "Typhoid", A: 85, B: 65, fullMark: 150 },
];

export default function PatientScatterChart() {
  return (
    <div className="w-full h-96">
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 150]} />
          <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          <Radar name="Lily" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.8} />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}
