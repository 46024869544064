import { CircularProgress } from "@mui/material";
import React from "react";

export default function FilledButton({
  IconFirst,
  iconFirstColor,
  text,
  textColor = "white",
  IconLast,
  iconLastColor,
  radius = "lg", // default radius
  bgColor = "primaryGreen", // default background color
  showLoader = false,
  loaderColor = "white",
  btnClicked,
  btnType = null,
}) {
  return (
    <button
      type={btnType}
      disabled={showLoader}
      className={`w-full p-3 px-4 flex items-center justify-center gap-1 font-medium text-${textColor} rounded-${radius} text-sm ${textColor} bg-${bgColor}`}
      onClick={btnClicked}
    >
      {!showLoader && IconFirst && (
        <IconFirst style={{ fontSize: "1rem" }} htmlColor={iconFirstColor} />
      )}
      {!showLoader && <p className="leading-none">{text}</p>}
      {!showLoader && IconLast && (
        <IconLast style={{ fontSize: "1rem" }} htmlColor={iconLastColor} />
      )}
      {showLoader && (
        <CircularProgress size={20} style={{ color: loaderColor }} />
      )}
    </button>
  );
}
