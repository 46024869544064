import React from "react";

export default function MedicalHistory({ data }) {
  return (
    <div className="rounded-lg border border-borderGray px-5 py-6 gap-6 flex flex-col">
      <div className="flex justify-between items-center">
        <p>Medical History</p>
      </div>
      <div className="flex flex-col gap-3 text-primaryGray">
        {data?.length !== 0 ? (
          data?.map((el, index) => {
            return (
              <div className="" key={index}>
                <p>{el}</p>
              </div>
            );
          })
        ) : (
          <p className="text-center">No Medical History Records Found</p>
        )}
      </div>
    </div>
  );
}
