import { getUserId, getUserRole } from "../middleware/authUtils";
import { apiClient } from "./api.client";

export const fetchPrescriptions = async (patientId) => {
  try {
    const response = await apiClient.get(`/prescriptions/patient/${patientId}`);
    return response.data;
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const fetchPatients = async () => {
  try {
    const role = getUserRole();
    const userId = getUserId();
    const response = await apiClient.get(`/patients/${role}/${userId}`);
    return {
      status: "success",
      statusCode: response.status,
      data: response.data,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.response.data.message,
      statusCode: error.response.data.statusCode,
    };
  }
};

export const fetchMetaData = async (userId) => {
  try {
    const response = await apiClient.get(`/meta-data/${userId}`);
    return {
      status: "success",
      statusCode: response.status,
      data: response.data,
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const fetchAppointments = async (role, userId) => {
  try {
    const response = await apiClient.get(
      `/appointments/all?userId=${userId}&role=${role}`
    );
    return {
      status: "success",
      statusCode: response.status,
      data: response.data,
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};
