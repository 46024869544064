import "../App.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoVerical from "../logoVertical.svg";

function App() {
  const navigate = useNavigate();

  const [userCred, setUserCred] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    otp: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserCred((prevCred) => ({
      ...prevCred,
      [name]: value,
    }));
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (userCred.password === userCred.confirmPassword) {
      // Handle form submission here, such as sending credentials to an API
    } else {
      alert("Passwords dont match");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={LogoVerical} className="w-24 mb-6 sm:w-40 lg:w-36" alt="Logo" />
      <form
        onSubmit={handleSubmit}
        className="bg-white w-1/3 p-6 rounded-lg shadow-lg"
      >
        <div className="mb-4">
          <input
            className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Email"
            type="email"
            name="email"
            value={userCred.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <input
            className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Password"
            type="password"
            name="password"
            value={userCred.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <input
            className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            value={userCred.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          onClick={() => {navigate("/dashboard")}}
          className="w-full bg-primaryGreen text-white py-2 rounded-lg disabled:bg-primaryGray"
          disabled
        >
          {/* Signup */}
          Currently Invite-Only
        </button>
        <div className="flex justify-start">
          <p className="text-sm text-neutral-600 mr-1">Already a User? </p>
          <Link
            to="/"
            className="text-sm decoration-neutral-600 text-neutral-600 underline hover:text-primaryGreen "
          >
            Login
          </Link>
        </div>
      </form>
    </div>
  );
}

export default App;
