import React, { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import OutlinedButton from "../CommonComponents/OutlinedButton";
import PrescriptionPdf from "./PrescriptionPdf";
import FilledButton from "../CommonComponents/FilledButton";
import { postPrescription, uploadFiles } from "../Network/prescription.network";

const DownloadPdfButton = ({
  doctor,
  patient,
  processedFormData,
  previewPrescription,
  finalAudioBlob = null,
  isPrescriptionEdited,
  onClose = () => {},
}) => {
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const createPrescriptionBlob = async (previewPrescription = false) => {
    try {
      const blob = await pdf(
        <PrescriptionPdf
          diagnosis={processedFormData?.diagnosis}
          doctor={doctor}
          patient={patient}
          medications={processedFormData?.medication}
          test_suggested={processedFormData?.test_suggested}
          test_reports={processedFormData?.test_results}
          notes={processedFormData?.medical_notes}
        />
      ).toBlob();
      setPdfBlob(blob);
      if (previewPrescription) {
        const link = document.createElement("a");
        const blobUrl = URL.createObjectURL(blob); // Generate a Blob URL
        link.href = blobUrl;
        link.download = "Prescription.pdf"; // Set the desired filename
        document.body.appendChild(link); // Append the link temporarily to the document
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove the link from the document
        URL.revokeObjectURL(blobUrl); // Free memory by revoking the Blob URL
      }
      return blob;
    } catch (error) {
      console.log(error);
      alert("Error in generating the PDF. Please try again later.");
    }
  };

  const postPrescriptionData = async () => {
    setIsBtnClicked(true);
    let presBlob = pdfBlob;
    if (presBlob === null) {
      presBlob = await createPrescriptionBlob(false);
    }
    const mediaFileResponse = await uploadFiles(
      finalAudioBlob,
      presBlob,
      patient?.id
    );
    const response = await postPrescription({
      ...processedFormData,
      ...mediaFileResponse.data,
      is_final_prescription: true,
      is_edited: isPrescriptionEdited
    });
    if (response.statusCode === 201) {
      onClose();
    } else {
      alert("Something went wrong");
    }
    setPdfBlob(null);
    setIsBtnClicked(false);
  };

  return (
    <div className="w-full">
      {previewPrescription ? (
        <OutlinedButton
          text="Preview Prescription"
          textColor="text-primaryGreen"
          borderColor="primaryGreen"
          btnClicked={() => createPrescriptionBlob(true)}
        />
      ) : (
        <FilledButton
          bgColor={"primaryGreen"}
          text={"Save Prescription"}
          radius={"lg"}
          btnClicked={postPrescriptionData}
          showLoader={isBtnClicked}
        />
      )}
    </div>
  );
};

export default DownloadPdfButton;
