import React from "react";
import Input from "../../CommonComponents/Input";
import FilledButton from "../../CommonComponents/FilledButton";
import { AddRounded, Delete } from "@mui/icons-material";

export default function MedicinePopupComponent({
  formData,
  setFormData,
  setIsEdited,
}) {
  const addMedicine = () => {
    setIsEdited(true);
    setFormData({
      ...formData,
      medication: [
        ...formData?.medication,
        {
          medicine_name: "",
          dosage: "",
          days: "",
          is_sos: false,
          food: {
            before_breakfast: false,
            after_breakfast: false,
            lunch: false,
            dinner: false,
          },
          frequency: {
            od: false,
            bid: false,
            tid: false,
            qid: false,
            hs: false,
            ac: false,
            pc: false,
          },
        },
      ],
    });
  };

  const handleDeleteMedicine = (index) => {
    setIsEdited(true);
    const updatedMedication = (formData?.medication || []).filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, medication: updatedMedication });
  };

  const handleMedicineChange = (index, field, value) => {
    setIsEdited(true);
    const updatedMedication = [...(formData?.medication || [])];
    updatedMedication[index] = { ...updatedMedication[index], [field]: value };
    setFormData({ ...formData, medication: updatedMedication });
  };

  const handleFoodChange = (index, key) => {
    setIsEdited(true);
    const updatedMedication = (formData?.medication || [])?.map(
      (medicine, medIndex) =>
        medIndex === index
          ? {
              ...medicine,
              food: {
                ...medicine.food,
                [key]: !medicine.food?.[key],
              },
            }
          : medicine
    );

    setFormData({
      ...formData,
      medication: updatedMedication,
    });
  };

  const handleDosageChange = (index, value) => {
    setIsEdited(true);
    const updatedMedication = [...(formData.medication || [])];
    updatedMedication[index] = {
      ...updatedMedication[index],
      dosage: value,
    };
    setFormData({
      ...formData,
      medication: updatedMedication,
    });
  };

  const handleSOSCheck = (index, checked) => {
    setIsEdited(true);
    const updatedMedication = [...(formData.medication || [])];
    updatedMedication[index] = {
      ...updatedMedication[index],
      is_sos: checked,
    };
    setFormData({
      ...formData,
      medication: updatedMedication,
    });
  };

  const handleFrequencyChange = (index, key) => {
    setIsEdited(true);

    const updatedMedication = (formData?.medication || [])?.map(
      (medicine, medIndex) =>
        medIndex === index
          ? {
              ...medicine,
              frequency: {
                ...Object.keys(medicine?.frequency || {}).reduce(
                  (acc, freqKey) => {
                    acc[freqKey] = freqKey === key;
                    return acc;
                  },
                  {}
                ),
              },
            }
          : medicine
    );
    setFormData({
      ...formData,
      medication: updatedMedication,
    });
  };

  return (
    <div className="rounded-lg border border-borderGray p-3 sm:p-4 flex flex-col gap-3">
      <p className="pb-2">Medicine Details</p>
      {(formData?.medication || [])?.map((medicine, index) => (
        <React.Fragment key={index}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <Input
              title={"Medicine Name"}
              value={medicine?.medicine_name}
              onChange={(e) =>
                handleMedicineChange(index, "medicine_name", e.target.value)
              }
            />
            <div className="grid sm:grid-cols-12 gap-2">
              <div className="col-span-5">
                <Input
                  title={"Dosage"}
                  value={medicine?.dosage}
                  onChange={(e) => handleDosageChange(index, e.target.value)}
                />
              </div>
              <div className="col-span-5">
                <Input
                  title={"Number of Days"}
                  value={medicine?.days}
                  onChange={(e) =>
                    handleMedicineChange(index, "days", e.target.value)
                  }
                />
              </div>
              <div className="flex items-center self-end gap-2 sm:pb-1">
                <button
                  className="p-2 rounded-md bg-red-200 text-primaryRed"
                  onClick={() => handleDeleteMedicine(index)}
                >
                  <Delete />
                </button>
              </div>
            </div>
          </div>

          {/* Frequency Section */}
          <div className="">
            <p className="mb-2">Frequency</p>
            <div className="flex flex-row items-center gap-2">
              <input
                className="accent-primaryGreen w-4 h-4"
                type="checkbox"
                onChange={(e) => {
                  handleSOSCheck(index, e.target.checked);
                }}
                checked={formData?.medication?.[index]?.is_sos}
              />
              <p className="text-sm sm:text-base">SOS</p>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {[
                { label: "Before Breakfast", key: "before_breakfast" },
                { label: "After Breakfast", key: "after_breakfast" },
                { label: "Lunch", key: "lunch" },
                { label: "Dinner", key: "dinner" },
              ]?.map(({ label, key }) => (
                <div key={label} className="flex flex-row items-center gap-2">
                  <input
                    className="accent-primaryGreen w-4 h-4"
                    type="checkbox"
                    checked={!!medicine?.food?.[key]}
                    onChange={() => {
                      handleFoodChange(index, key);
                    }}
                  />
                  <p className="text-sm sm:text-base">{label}</p>
                </div>
              ))}
            </div>
          </div>
          <p className="text-center">OR</p>
          <div>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {[
                { label: "Once Daily", key: "od" },
                { label: "Twice Daily", key: "bid" },
                { label: "Three Times Daily", key: "tid" },
                { label: "Four Times Daily", key: "qid" },
                { label: "At Bedtime", key: "hs" },
                { label: "Before Meals", key: "ac" },
                { label: "After Meals", key: "pc" },
              ]?.map(({ label, key }) => (
                <div key={label} className="flex flex-row items-center gap-2">
                  <input
                    className="accent-primaryGreen w-4 h-4"
                    type="radio"
                    checked={!!medicine?.frequency?.[key]}
                    onChange={() => handleFrequencyChange(index, key)}
                  />
                  <p className="text-sm sm:text-base">{label}</p>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}

      <FilledButton
        text={"Add new"}
        bgColor="secondaryGreen"
        textColor="primaryGreen"
        IconFirst={AddRounded}
        btnClicked={addMedicine}
      />
    </div>
  );
}
