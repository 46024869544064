import React, { useEffect, useState } from "react";
import CustomSideBar from "../components/CustomSideBar";
import Button from "../CommonComponents/Button";
import { KeyboardBackspaceRounded, ReceiptLong } from "@mui/icons-material";
import PatientOverview from "./Components/PatientOverview";
import Appointment from "../Dashboard/Components/Appointment";
import Vitals from "./Components/Vitals";
import {
  fetchAppointments,
  fetchMetaData,
  fetchPrescriptions,
} from "../Network/patient.screen";
import MedicalHistory from "./Components/MedicalHistory";
import FilesAndDocs from "./Components/FilesAndDocs";
import PrescriptionComponent from "./Components/PrescriptionComponent";
import PrescriptionStylePopup from "./Popup/PrescriptionStylePopup";
import RecordPopup from "./Popup/RecordPopup";
import PrescriptionFormPopup from "./Popup/PrescriptionFormPopup";
import { useNavigate, useParams } from "react-router-dom";
import { getUserRole } from "../middleware/authUtils";
import VitalsPopup from "./Popup/VitalsPopup";
import EditMetaDataPopup from "./Popup/EditMetaDataPopup";

const UserRole = {
  ADMIN: "admin",
  DOCTOR: "doctor",
  NURSE: "nurse",
  PATIENT: "patient",
};

export default function Patient() {
  const [patientData, setPatientData] = useState([]);
  const [isPresStylePopupOpen, setIsPresStylePopupOpen] = useState(false);
  const [isCompehendPopupOpen, setIsComprehendPopupOpen] = useState(false);
  const [isPrescFormOpen, setIsPresFormOpen] = useState(false);
  const [isVitalsPopupOpen, setIsVitalsPopupOpen] = useState(false);
  const [isMetaDataPopupOpen, setIsMetaDataPopupOpen] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [presData, setPresData] = useState(null);

  const [medicalHistory, setMedicalHistory] = useState([]);
  const [filesAndDocs, setFilesAndDocs] = useState([]);

  useEffect(() => {
    if (Array.isArray(patientData)) {
      const histories =
        patientData?.filter((data) => data?.history?.trim())
          ?.map((data) => data.history) || [];
  
      setMedicalHistory(histories);
  
      const docs =
        patientData?.map((data) => ({
          presUrl: data?.presUrl || null,
          audioUrl: data?.audioUrl || null,
        })) || [];
  
      const allNull = docs.every(
        (doc) => doc.presUrl === null && doc.audioUrl === null
      );
  
      setFilesAndDocs(allNull ? [] : docs);
    } else {
      // Fallback if patientData is not an array
      setMedicalHistory([]);
      setFilesAndDocs([]);
    }
  }, [patientData]);

  const [vitals, setVitals] = useState({
    oxy: "",
    weight: "",
    temp: "",
    pulse: "",
    bp: {
      systolic: "",
      diastolic: "",
    },
  });

  const [appointmentsData, setAppointmentsData] = useState(null);
  const { id } = useParams();
  const role = getUserRole();
  const navigate = useNavigate();
  const [finalAudioBlob, setFinalAudioBlob] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchPrescriptions(id);
      const metaResponse = await fetchMetaData(id);
      const appointments = await fetchAppointments("patient", id);
      if (metaResponse.statusCode === 200) {
        setMetaData(metaResponse.data);
      }
      if (
        appointments.statusCode === 200 &&
        appointments.status === "success"
      ) {
        setAppointmentsData(appointments.data);
      }
      setPatientData(response);
    };
    fetchData();
  }, []);

  return (
    <div className="flex flex-col sm:flex-row">
      <CustomSideBar className="hidden sm:block" />
      <div className="sm:pl-72 px-4 sm:px-8 flex py-6 flex-col gap-4">
        <div className="flex justify-between items-center flex-wrap">
          <Button
            StartIcon={KeyboardBackspaceRounded}
            text={"Back to Patients"}
            textColor={"text-primaryGreen"}
            onClick={() => navigate("/patients")}
          />
          <div className="flex gap-3 mt-2 sm:mt-0">
            {role === UserRole.DOCTOR && (
              <button
                className="border border-borderGray border-lg flex items-center justify-center gap-2 rounded-lg p-2 px-3 text-primaryGreen"
                onClick={() => setIsPresStylePopupOpen(true)}
              >
                <ReceiptLong />
                Add Prescription
              </button>
            )}
            {(role === UserRole.DOCTOR || role === UserRole.NURSE) && (
              <button
                className="border border-borderGray border-lg flex items-center justify-center gap-2 rounded-lg p-2 px-3 text-primaryGreen"
                onClick={() => setIsVitalsPopupOpen(true)}
              >
                <ReceiptLong />
                Add Vitals
              </button>
            )}
          </div>
        </div>
        <PatientOverview
          metaData={metaData}
          setIsMetaDataPopup={() => setIsMetaDataPopupOpen(true)}
        />

        <PrescriptionComponent prescriptions={patientData} />
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-3">
          <div className="lg:col-span-3 flex flex-col gap-3">
            <Vitals />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <MedicalHistory data={medicalHistory} />
              <FilesAndDocs data={filesAndDocs} />
            </div>
          </div>
          <div className="lg:col-span-2 rounded-lg border border-borderGray">
            <Appointment pageTitle={"patient"} data={appointmentsData} />
          </div>
        </div>
      </div>
      <PrescriptionStylePopup
        isOpen={isPresStylePopupOpen}
        onClose={() => setIsPresStylePopupOpen(false)}
        voicePresClicked={() => {
          setIsComprehendPopupOpen(true);
          setIsPresStylePopupOpen(false);
        }}
        isWritePresClicked={() => {
          setIsPresFormOpen(true);
          setIsPresStylePopupOpen(false);
        }}
      />
      <RecordPopup
        isOpen={isCompehendPopupOpen}
        setIsPrescFormOpen={() => setIsPresFormOpen(true)}
        onClose={() => setIsComprehendPopupOpen(false)}
        setPrescriptionResponse={setPresData}
        setFinalAudioBlob={setFinalAudioBlob}
      />
      <PrescriptionFormPopup
        metaData={{ ...metaData, vitals: patientData?.[0]?.vitals }}
        isOpen={isPrescFormOpen}
        onClose={() => setIsPresFormOpen(false)}
        presData={presData}
        finalAudioBlob={finalAudioBlob}
      />
      <EditMetaDataPopup
        isOpen={isMetaDataPopupOpen}
        metaData={metaData}
        setMetaData={setMetaData}
        patientId={id}
        onClose={() => setIsMetaDataPopupOpen(false)}
      />
      <VitalsPopup
        isOpen={isVitalsPopupOpen}
        onClose={() => setIsVitalsPopupOpen(false)}
        vitals={vitals}
        setVitals={setVitals}
      />
    </div>
  );
}
