import React, { useEffect, useState } from "react";
import AllPatientTable from "./Components/AllPatientTable";
import FilledButton from "../CommonComponents/FilledButton";
import SearchBar from "../CommonComponents/SearchBar";
import { Add } from "@mui/icons-material";
import CustomSideBar from "../components/CustomSideBar";
import { fetchPatients } from "../Network/patient.screen";
import { getUserRole } from "../middleware/authUtils";
import AddPatientPopup from "../Patient/Popup/AddPatientPopup";

const tableHeaders = [
  "PATIENT NAME",
  "AGE",
  "GENDER",
  "DIAGNOSIS",
  "PHONE NUMBER",
  "APPOINTMENT",
  "ACTIONS",
];

export default function PatientTableScreen() {
  const [patientData, setPatientData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [todayChecked, setTodayChecked] = useState(false);
  const [filteredPatientData, setFilteredPatientData] = useState([]);
  const [listAllPatientsOfAdmin, setListAllPatientsOfAdmin] = useState(false);
  const role = getUserRole();

  useEffect(() => {
    const fetchPatientData = async () => {
      const response = await fetchPatients();
      if (response.statusCode === 200) {
        setOriginalData(response.data);
        setPatientData(response.data); // Set the patient data after fetching
      } else {
        setOriginalData([]);
        setPatientData([]);
      }
    };
    fetchPatientData();
  }, []);

  useEffect(() => {
    if (originalData.length > 0) {
      const filtered = originalData.filter((patient) => {
        const matchesDate =
          !todayChecked ||
          (patient?.latestAppointmentTiming &&
            patient?.latestAppointmentTiming.split("T")[0] ===
              new Date().toISOString().split("T")[0]);

        const matchesSearch =
          !searchVal ||
          patient.name?.toLowerCase().includes(searchVal) ||
          patient.contact?.phone_number.toLowerCase().includes(searchVal);

        return matchesSearch && matchesDate;
      });
      setPatientData(filtered);
    }
  }, [originalData, searchVal, todayChecked]);

  const handleChange = (e) => {
    const searchTerm = e?.target?.value.toLowerCase();
    setSearchVal(searchTerm);
    filterPatients(searchTerm, todayChecked);
  };

  const filterPatients = (searchTerm, isTodayChecked) => {
    const baseData = isTodayChecked ? filteredPatientData : originalData;

    // Filter patients by search term and date
    const filtered = baseData.filter((patient) => {
      const matchesSearch =
        !searchTerm ||
        patient.name?.toLowerCase().includes(searchTerm) ||
        patient.contact?.phone_number.toLowerCase().includes(searchTerm);

      const matchesDate =
        !isTodayChecked ||
        (patient.latestAppointmentTiming &&
          patient.latestAppointmentTiming.split("T")[0] ===
            new Date().toISOString().split("T")[0]);

      return matchesSearch && matchesDate;
    });

    setPatientData(filtered);
  };
  
  const [isAddPatientPopupOpen, setIsAddPatientPopupOpen] = useState(false)

  return (
    <div className="p-8 sm:pl-0 flex flex-col bg-white h-screen">
      <CustomSideBar />
      <AddPatientPopup isOpen={isAddPatientPopupOpen} onClose={() => setIsAddPatientPopupOpen(false)}/>
      <div className="sm:pl-72 flex flex-col gap-4">
        <div className="sm:flex sm:gap-2 items-center justify-between">
          <div className="pb-2 sm:pb-0">
            <p className="text-2xl font-medium text-primaryGreen">Patients</p>
            <p className="font-light text-lg text-primaryGray">
              View all your patient information
            </p>
          </div>
          {/* <div className="flex items-center gap-2"> */}
          {/* <OutlinedButton
              text={"Print"}
              IconFirst={Print}
              textColor={"text-primaryGreen"}
              iconFirstColor={"text-primaryGreen"}
            />
            <OutlinedButton
              text={"Export"}
              IconLast={KeyboardArrowDown}
              textColor={"text-primaryGreen"}
              iconLastColor={"text-primaryGreen"}
            /> */}
          <div className="flex">
            <FilledButton
              radius={"lg"}
              bgColor={"primaryGreen"}
              text={"Add Patient"}
              IconFirst={Add}
              btnClicked={() => setIsAddPatientPopupOpen(true)}
            />
          </div>
          {/* </div> */}
        </div>
        <div className="sm:flex gap-2 items-center justify-between ">
          <div className="flex pb-2 sm:pb-0 items-center gap-2">
            {/* <OutlinedButton
              text={"Start Date"}
              IconFirst={CalendarToday}
              textColor={"text-essentialGray"}
              iconFirstColor={"disabled"}
            />
            <OutlinedButton
              text={"End Date"}
              textColor={"text-essentialGray"}
              IconFirst={CalendarToday}
              iconFirstColor={"disabled"}
            /> */}
            <div className="flex items-center font-light gap-2 whitespace-nowrap accent-primaryGreen">
              {role === "admin" && (
                <>
                  <input
                    type="checkbox"
                    checked={listAllPatientsOfAdmin}
                    onChange={() =>
                      setListAllPatientsOfAdmin(!listAllPatientsOfAdmin)
                    }
                  />
                  <p>List All Patients</p>
                </>
              )}

              <input
                type="checkbox"
                checked={todayChecked}
                onChange={() => setTodayChecked(!todayChecked)}
              />
              <p>Todays Appointments</p>
            </div>
          </div>
          <SearchBar
            value={searchVal}
            onChange={handleChange}
            placeholderText={"Type input here..."}
          />
        </div>
        <AllPatientTable
          navigateTo={"/patient"}
          dataCount={100}
          tableData={patientData}
          tableHeader={tableHeaders}
        />
      </div>
    </div>
  );
}
