import { CloseRounded } from "@mui/icons-material";
import React from "react";
import Input from "../../CommonComponents/Input";
import FilledButton from "../../CommonComponents/FilledButton";

export default function RegisterDoctorPopup({
  isOpen = true,
  onClose,
}) {
    if (!isOpen) return null
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* Popup content */}
      <div className="bg-white rounded-lg p-6 z-10 max-w-screen-lg w-full mx-4 sm:mx-6 md:mx-8 lg:mx-12 flex flex-col gap-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <p className="text-lg font-semibold">Register Doctor</p>
          <button className="text-primaryRed" onClick={onClose}>
            <CloseRounded />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Input title={"Primary email"} />
          <Input title={"Password"} type="password" />
          <div className="flex flex-col w-full gap-2">
            <p>Staff Type</p>
            <select className="p-3 border border-borderGray rounded-lg text-primaryGray w-full">
              <option value="">
                Choose staff
              </option>
              <option value="admin">Admin</option>
              <option value="doctor">Doctor</option>
              <option value="nurse">Nurse</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col gap-4 border border-borderGray rounded-lg p-4">
          <p>Basic Details</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input title={"Doctor name"} />
            <Input title={"Age"} type="number" />
            <Input title={"Date of birth"} type="date" />
            <div className="flex flex-col w-full gap-2">
              <p>Sex</p>
              <select className="p-3 border border-borderGray rounded-lg text-primaryGray w-full">
                <option value="" selected>
                  Choose gender
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="O">Others</option>
              </select>
            </div>
          </div>
          <p>Permissions</p>
          <input
            id="green-checkbox"
            type="checkbox"
            className="accent-primaryGreen w-4 h-4"
          />
        </div>

        <div className="flex flex-col gap-4 border border-borderGray rounded-lg p-4">
          <p>Contact details</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input title={"Alternative email"} />
            <Input title={"Phone number"} />
            <Input title={"Address line 1"} />
            <Input title={"Pincode"} />
            <Input title={"City"} />
            <Input title={"State"} />
          </div>
        </div>

        <div className="flex flex-col gap-4 border border-borderGray rounded-lg p-4">
          <p>Career details</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input title={"Qualification"} />
            <Input title={"Degree"} />
            <Input title={"Experience"} type="number" />
            <Input title={"License Number"} />
            <Input title={"Language"} />
            <Input title={"Certificate"} type="file" multiple={true} />
          </div>
        </div>
        <FilledButton bgColor="primaryGreen" text={"Register New Staff"}/>
      </div>
    </div>
  );
}
