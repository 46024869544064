import { apiClient } from "./api.client";

export const loginUser = async (userCred) => {
  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_BASE_URL}/login`,
      { email: userCred.email, password: userCred.password }
    );
    return {
      data: response.data,
      message: "success",
      statusCode: response.status,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.response.data.message,
      statusCode: error.response.data.statusCode,
    };
  }
};
