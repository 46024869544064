import React from "react";

const checkTrueFrequency = (freqObj) => {
  for (const [key, value] of Object.entries(freqObj)) {
    if (value) {
      return key; // Return the key where the value is true
    }
  }
  return "NaN";
};

export default function DosageCheckbox({ food, frequency }) {
  return (
    <div className="items-center flex justify-left gap-1">
      {food ? (
        Object.entries(food).map((el, index) => {
          return (
            <input
              key={index}
              id="green-checkbox"
              type="checkbox"
              checked={el[1]}
              className="accent-primaryGreen w-4 h-4"
            />
          );
        })
      ) : (
        <p className="uppercase px-2 py-1 rounded-lg  bg-primaryRed text-primaryRed bg-opacity-5">
          {checkTrueFrequency(frequency)}
        </p>
      )}
    </div>
  );
}
