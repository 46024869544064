import React from "react";
import { Bar, BarChart, XAxis, ResponsiveContainer } from "recharts";

const data = [
  { name: "Monday", uv: 4000, pv: 2400 },
  { name: "Tuesday", uv: 3000, pv: 1398 },
  { name: "Wednesday", uv: 2000, pv: 9800 },
  { name: "Thursday", uv: 2780, pv: 3908 },
  { name: "Friday", uv: 1890, pv: 4800 },
  { name: "Saturday", uv: 2390, pv: 3800 },
  { name: "Sunday", uv: 3490, pv: 4300 },
];

export default function AppointmentBarGraph() {
  return (
    <div className="mt-4 w-full">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 10 }}
            interval={0}
            dataKey="name"
          />
          <Bar dataKey="uv" stackId="a" fill="#DAFB56" />
          <Bar dataKey="pv" radius={[8, 8, 0, 0]} stackId="a" fill="#2F887C" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
