import { LocalHospital, People } from "@mui/icons-material";
import React from "react";

export default function DetailSection({ title }) {
  return (
    <div>
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <p className="text-lg font-medium">{title}</p>
        <button className="border px-4 py-2 rounded-xl mt-4 sm:mt-0">
          See Details
        </button>
      </div>

      {/* Stats Section */}
      <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
        <div className="flex items-center gap-4">
          {title === "Appointments" ? (
            <People
              color="success"
              fontSize="large"
              className="p-2 border rounded-lg"
            />
          ) : (
            <LocalHospital
              color="success"
              fontSize="large"
              className="p-2 border rounded-lg"
            />
          )}
          <p className="text-xl font-semibold">1,879</p>
          <p className="p-1 px-2 text-sm bg-green-100 text-green-900 rounded-lg">
            +115%
          </p>
        </div>

        <div className="flex items-center gap-2 mt-4 sm:mt-0 text-sm">
          <div className="flex gap-2 items-center">
            <div className="rounded-full bg-green-900 w-3 h-3" />
            <p>Patients Seen</p>
          </div>
          <div className="flex gap-2 items-center">
            <div
              className="rounded-full w-3 h-3"
              style={{ backgroundColor: "#DAFB56" }}
            />
            <p>Appoinments Booked</p>
          </div>
        </div>
      </div>
    </div>
  );
}
