import React, { useEffect } from "react";
import DataTable from "./Components/DataTable";
import Appointment from "./Components/Appointment";
import AppointmentGraph from "./Components/AppointmentGraph";
import PatientScatterComponent from "./Components/PatientScatterComponent";
import CustomSideBar from "../components/CustomSideBar";
import { fetchDashboardData } from "../Network/dashboard.screen";
import PatientAndAppointmentCard from "./Components/PatientAndAppointmentCard";

export default function Dashboard() {
  useEffect(() => {
    const fetchPatients = async () => {
      const data = await fetchDashboardData();
      // await fetchAllAppointments()
    };

    fetchPatients();
  }, []);

  return (
    <>
      <CustomSideBar />
      <div className="p-4 flex flex-col gap-4 pl-0 sm:pl-72">
        {/* Content Wrapper */}
        <div className="relative flex-1">
          {/* Coming Soon Message */}
          {/* <div className="inset-0 flex items-center justify-center z-10">
            <h1 className="text-primaryGreen text-3xl font-bold">
              Analytics Dashboard Coming Soon
            </h1>
          </div> */}
          {/* Main Content (Blurred) */}
          <div className="flex flex-col gap-4 ">
            <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
              {/* Appointment Graph */}
              <div className="col-span-12 sm:col-span-6 grid gap-4">
                <PatientAndAppointmentCard cardType={"Patient"} />
                <PatientAndAppointmentCard />
              </div>
              {/* Patient Scatter Component */}
              <div className="col-span-12 sm:col-span-6">
                <PatientScatterComponent />
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <AppointmentGraph />
              </div>
              {/* Data Table */}
              <div className="col-span-12 sm:col-span-8">
                <DataTable />
              </div>
              {/* Appointments Section */}
              <div className="col-span-12 sm:col-span-4">
                <Appointment />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
