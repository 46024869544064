import React, { useState } from "react";

export default function SidebarComponent({ title, Icon, path, isActive }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div>
      <a
        href={path}
        className={`flex items-center p-2 text-gray-900 hover:text-primaryGreen rounded-lg hover:bg-secondaryGreen ${isActive ? `text-primaryGreen bg-secondaryGreen` : ``}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Icon className={isHovered ? `text-primaryGreen` : `text-gray-400 ${isActive ? `text-primaryGreen bg-secondaryGreen` : ``}`} />
        {/* <img src={isHovered ? highlightedIcon : icon} className="w-5" /> */}

        <span className="ms-3 font-sans">{title}</span>
      </a>
    </div>
  );
}
