import React from "react";
import PatientOverview from "../Patient/Components/PatientOverview";
import CustomSideBar from "../components/CustomSideBar";
import DoctorDetails from "./Components/DoctorDetails";
import { KeyboardBackspaceRounded } from "@mui/icons-material";

export default function Doctor() {
  return (
    <div className=" ">
      <CustomSideBar className="hidden sm:block" />
      <div className="sm:pl-72 px-4 sm:px-8 flex py-6 flex-col gap-4">
        <button className="text-left w-fit text-primaryGreen flex items-center gap-1">
          <KeyboardBackspaceRounded />
          Back to Staff
        </button>
        <PatientOverview />
        <DoctorDetails />
      </div>
    </div>
  );
}
