import { Download, Print } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date)) {
    return;
  }

  const options = { weekday: "long" }; // This will give the full name of the day (e.g., "Friday")
  const day = date.toLocaleDateString("en-US", options);

  const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
  const time = date.toLocaleTimeString("en-US", timeOptions);

  return `${day}, ${time}`;
};

export default function AllPatientTable({
  patientCount,
  navigateTo = "",
  tableData = [],
  tableHeader = [],
}) {
  const startIndex = 1;
  const endIndex = tableData.length;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4">
      <div className="overflow-x-auto rounded-lg border border-borderGray">
        <table className="w-full text-sm text-left">
          <thead className="text-xs font-semibold text-essentialGray uppercase bg-gray-100 rounded-t-lg">
            <tr>
              {tableHeader?.map((header, index) => (
                <th key={index} scope="col" className="px-4 sm:px-6 py-3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {navigateTo.includes("patient")
              ? tableData.map((patient, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } ${
                      index === tableData.length - 1
                        ? "rounded-b-lg"
                        : "border-b"
                    } hover:bg-gray-200 cursor-pointer`}
                    onClick={() => {
                      navigate(`${navigateTo}/${patient.uid}`);
                    }}
                  >
                    <td
                      scope="row"
                      className="px-4 sm:px-6 py-4 text-gray-900 whitespace-nowrap"
                    >
                      {patient?.name}
                    </td>
                    <td className="px-4 sm:px-6 py-4">
                      {patient?.metaData?.dob ?? "NaN"}
                    </td>
                    <td className="px-4 sm:px-6 py-4">
                      {patient?.metaData?.sex ?? "NaN"}
                    </td>
                    <td className="px-4 sm:px-6 py-4">
                      {patient?.latestDiagnosis ?? "NaN"}
                    </td>
                    <td className="px-4 sm:px-6 py-4">
                      {patient?.contact?.phone_number ?? "NaN"}
                    </td>
                    <td className="px-4 sm:px-6 py-4">
                      {formatDate(patient?.latestAppointmentTiming) ?? "NaN"}
                    </td>
                    <td className="px-4 sm:px-6 py-4 gap-2 flex ">
                      <button className="text-primaryGreen">
                        <Print />
                      </button>
                      <button className="text-primaryGreen">
                        <Download />
                      </button>
                    </td>
                  </tr>
                ))
              : tableData.map((staff, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    } ${
                      index === tableData.length - 1
                        ? "rounded-b-lg"
                        : "border-b"
                    } hover:bg-gray-200 cursor-pointer`}
                    onClick={() => {
                      navigate(navigateTo);
                    }}
                  >
                    <td
                      scope="row"
                      className="px-4 sm:px-6 py-4 text-gray-900 whitespace-nowrap"
                    >
                      {staff.doctorName}
                    </td>
                    <td className="px-4 sm:px-6 py-4">{staff.age}</td>
                    <td className="px-4 sm:px-6 py-4">{staff.role}</td>
                    <td className="px-4 sm:px-6 py-4">{staff.qualification}</td>
                    <td className="px-4 sm:px-6 py-4">{staff.specialist}</td>
                    <td className="px-4 sm:px-6 py-4">{staff.phoneNumber}</td>
                    <td className="px-4 sm:px-6 py-4">{staff.email}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <p className="text-sm sm:text-base">{`Showing ${startIndex}-${
        endIndex
      } of ${tableData.length ?? "NaN"} Total Patients`}</p>
    </div>
  );
}
