import { SearchRounded } from "@mui/icons-material";
import React from "react";

const tableHeaders = ["PATIENT NAME", "AGE", "GENDER", "LAST VISIT", "STATUS"];

const patientData = [
  {
    name: "John Doe",
    age: 30,
    gender: "Male",
    lastVisit: "2024-10-20",
    status: "Active",
  },
  {
    name: "Jane Smith",
    age: 25,
    gender: "Female",
    lastVisit: "2024-10-18",
    status: "Active",
  },
  {
    name: "Robert Brown",
    age: 45,
    gender: "Male",
    lastVisit: "2024-10-15",
    status: "Inactive",
  },
];

export default function DataTable() {
  return (
    <div className="relative overflow-x-auto rounded-xl bg-white p-4 flex flex-col gap-4 border border-borderGray h-full">
      <div className="flex items-center justify-between">
        <p>Patients</p>
        <div className="border py-2 px-4 rounded-xl flex items-center gap-2">
          <SearchRounded color="disabled" fontSize="medium" />
          <input
            placeholder="Type input here.."
            className="outline-none rounded-xl"
          ></input>
        </div>
      </div>
      <div className="rounded-xl border">
        <table className="w-full text-sm text-left">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              {tableHeaders?.map((header, index) => (
                <th key={index} scope="col" className="px-6 py-3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {patientData.map((patient, index) => (
              <tr key={index} className="bg-white border-b">
                <td
                  scope="row"
                  className="px-6 py-4 text-gray-900 whitespace-nowrap"
                >
                  {patient.name}
                </td>
                <td className="px-6 py-4">{patient.age}</td>
                <td className="px-6 py-4">{patient.gender}</td>
                <td className="px-6 py-4">{patient.lastVisit}</td>
                <td className="px-6 py-4">{patient.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
