import { CloseRounded, CreateRounded, MicRounded } from "@mui/icons-material";
import React from "react";

export default function PrescriptionStylePopup({ isOpen, onClose, voicePresClicked, isWritePresClicked }) {
  if (!isOpen) return null; // Only render if open

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup content */}
      <div className="bg-white rounded-lg p-6 z-10 max-w-lg w-full">
        <div className="flex justify-between items-center mb-4">
          <p className="text-lg font-semibold">Create Prescription</p>
          <button className="text-red-600" onClick={onClose}>
            <CloseRounded />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-4 text-primaryGreen">
          <button className="bg-secondaryGreen rounded-lg p-5 flex flex-col items-center" onClick={isWritePresClicked}>
            <CreateRounded />
            <p>Write Prescription</p>
          </button>
          <button className="bg-secondaryGreen rounded-lg p-5 flex flex-col items-center" onClick={voicePresClicked}>
            <MicRounded />
            <p>Voice Prescription</p>
          </button>
        </div>
      </div>
    </div>
  );
}
