import { CloseRounded } from "@mui/icons-material";
import React, { useState } from "react";
import Input from "../../CommonComponents/Input";
import FilledButton from "../../CommonComponents/FilledButton";
import { postVitals } from "../../Network/prescription.network";
import { getUserId } from "../../middleware/authUtils";

export default function VitalsPopup({ isOpen, onClose, vitals, setVitals }) {
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const handleChange = (name, value) => {
    if(isNaN(Number(value))) {
      alert("Please enter number!");
      return;
    }
    const numericValue = value === "" ? "" : Number(value);
    setVitals((prevVitals) => {
      if (name === "bp.systolic" || name === "bp.diastolic") {
        const bpField = name.split(".")[1];
        return {
          ...prevVitals,
          bp: {
            ...prevVitals.bp,
            [bpField]: numericValue,
          },
        };
      }
      return {
        ...prevVitals,
        [name]: numericValue,
      };
    });
  };

  const addVitals = async () => {
    setIsBtnClicked(true);
    const userId = getUserId();
    const updatedVitalsData = { ...vitals, userId };
    const response = await postVitals(updatedVitalsData);
    if (response.statusCode === 201) {
      onClose();
    } else {
      alert("Something went wrong");
    }
    setIsBtnClicked(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup content */}
      <form className="bg-white rounded-lg p-4 md:p-6 z-10 max-w-sm md:max-w-lg w-full flex flex-col gap-4 sm:gap-4">
        <div className="flex justify-between items-center mb-2 md:mb-4">
          <p className="text-base md:text-lg font-semibold">Record Vitals</p>
          <button className="text-primaryRed" onClick={onClose}>
            <CloseRounded />
          </button>
        </div>
        <div className="flex flex-col gap-3 ">
          <Input
            title={"Temperature (°F) *"}
            value={vitals?.temp}
            onChange={(e) => handleChange("temp", e.target.value)}
          />
          <Input
            title={"Weight (KG) *"}
            value={vitals?.weight}
            onChange={(e) => handleChange("weight", e.target.value)}
          />
          <Input
            title={"O2 Saturation (%) *"}
            value={vitals?.oxy}
            onChange={(e) => handleChange("oxy", e.target.value)}
          />

          <div className="rounded-xl border border-borderGray p-3 md:p-4 gap-2 flex flex-col">
            <p className="text-sm md:text-base text-primaryText">
              Blood Pressure
            </p>
            <div className="grid grid-cols-2 gap-2 ">
              <Input
                title={"Systolic *"}
                value={vitals?.bp?.systolic}
                onChange={(e) => handleChange("bp.systolic", e.target.value)}
              />
              <Input
                title={"Diastolic *"}
                value={vitals?.bp?.diastolic}
                onChange={(e) => handleChange("bp.diastolic", e.target.value)}
              />
            </div>
            <Input
              title={"Heart Rate (BPM) *"}
              value={vitals?.pulse}
              onChange={(e) => handleChange("pulse", e.target.value)}
            />
          </div>
        </div>
        <FilledButton
          text={"Add"}
          className="self-center md:self-end"
          btnType={"submit"}
          btnClicked={addVitals}
          showLoader={isBtnClicked}
        />
      </form>
    </div>
  );
}
