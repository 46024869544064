export function formatDate(dateStr, daysToAdd = 0) {
  const date = new Date(dateStr);
  if (isNaN(date)) {
    return "Invalid Date";
  }

  date.setDate(date.getDate() + Number(daysToAdd));

  // Format the date as "DD MMM YYYY"
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;   
}

export const findAge = (dateStr) => {
  const birthDate = new Date(dateStr); // Parse the date string into a Date object
  const currentDate = new Date(); // Get the current date

  let age = currentDate.getFullYear() - birthDate.getFullYear(); // Calculate the age based on the year

  // Adjust the age if the birthday hasn't occurred yet this year
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
  ) {
    age--; // If the birthday hasn't happened yet this year, subtract 1 from the age
  }

  return age;
};

export function formatTime(dateString) {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // '0' should be '12' in 12-hour format

  // Pad minutes with leading zero if needed
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutesFormatted} ${ampm}`;
}
