import React, { useState } from "react";

import {
  Add,
} from "@mui/icons-material";
import CustomSideBar from "../components/CustomSideBar";
import SearchBar from "../CommonComponents/SearchBar";
import AllPatientTable from "../PatientOverview/Components/AllPatientTable";
import FilledButton from "../CommonComponents/FilledButton";
import RegisterDoctorPopup from "../Doctor/Popup/RegisterDoctorPopup";

const staffHeaders = [
  "Doctor Name",
  "Age",
  "Role",
  "Qualification",
  "Specialisation",
  "Phone Number",
  "Email",
];

const staffData = [
  {
    doctorName: "Dr. Alice Johnson",
    age: 45,
    role: "Doctor",
    qualification: "MD",
    specialist: "Cardiology",
    phoneNumber: "+1234567890",
    email: "alice.johnson@example.com",
  },
  {
    doctorName: "Dr. Brian Smith",
    age: 52,
    role: "Doctor",
    qualification: "MBBS, MS (Ortho)",
    specialist: "Orthopedics",
    phoneNumber: "+0987654321",
    email: "brian.smith@example.com",
  },
  {
    doctorName: "Dr. Clara Davis",
    age: 38,
    role: "Doctor",
    qualification: "MD Pediatrics",
    specialist: "Pediatrics",
    phoneNumber: "+1122334455",
    email: "clara.davis@example.com",
  },
  {
    doctorName: "Dr. Daniel Lee",
    age: 40,
    role: "Doctor",
    qualification: "MD, DM (Neurology)",
    specialist: "Neurology",
    phoneNumber: "+2233445566",
    email: "daniel.lee@example.com",
  },
  {
    doctorName: "Dr. Emma Martinez",
    age: 34,
    role: "Doctor",
    qualification: "MD Dermatology",
    specialist: "Dermatology",
    phoneNumber: "+3344556677",
    email: "emma.martinez@example.com",
  },
  {
    doctorName: "Nurse Hannah Green",
    age: 29,
    role: "Nurse",
    qualification: "RN, BSN",
    specialist: "N/A",
    phoneNumber: "+4455667788",
    email: "hannah.green@example.com",
  },
  {
    doctorName: "Nurse Isaac Brown",
    age: 32,
    role: "Nurse",
    qualification: "RN",
    specialist: "N/A",
    phoneNumber: "+5566778899",
    email: "isaac.brown@example.com",
  },
  {
    doctorName: "Nurse Julia White",
    age: 28,
    role: "Nurse",
    qualification: "LPN",
    specialist: "N/A",
    phoneNumber: "+6677889900",
    email: "julia.white@example.com",
  },
];

export default function Staff({ title }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <div className="p-8 sm:pt-16 sm:pl-0 flex flex-col bg-white h-screen">
      <CustomSideBar />
      <div className="sm:pl-72 flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-2xl font-medium text-primaryGreen">Staff</p>
            <p className="font-light text-lg text-primaryGray">
              View all your staff infromation
            </p>
          </div>
          <div className="flex items-center gap-2">
            <FilledButton
              radius={"lg"}
              bgColor={"primaryGreen"}
              text={"Add"}
              IconFirst={Add}
              btnClicked={() => setIsPopupOpen(true)}
            />
          </div>
        </div>
        <div className="flex items-center justify-end">
          <SearchBar placeholderText={"Type input here..."} />
        </div>
        <AllPatientTable
          navigateTo={"/staff"}
          patientCount={100}
          tableData={staffData}
          tableHeader={staffHeaders}
        />
        <RegisterDoctorPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        />
      </div>
    </div>
  );
}
