import { useEffect, useState } from "react";
import BPIcon from "../../icons/bp.svg";
import PulseIcon from "../../icons/pulse.svg";
import WeightIcon from "../../icons/weight.svg";
import TempIcon from "../../icons/temp.svg";
import OxyIcon from "../../icons/oxy.svg";

const VitalsCard = ({ icon, value, unit }) => {
  return (
    <div className="flex gap-4 items-center justify-center rounded-lg text-primaryGreen bg-primaryGreen bg-opacity-10 p-4">
      <img
        src={icon}
        className={`${icon === TempIcon ? `w-2` : `w-4`}`}
        alt={unit}
      />
      <p>
        {value === undefined || value === "undefined / undefined"
          ? "NaN"
          : `${value} ${unit}`}
      </p>
    </div>
  );
};

export default function PrescriptionVitals({ vitals }) {
  const [vitalsData, setVitalsData] = useState([]);
  useEffect(() => {
    setVitalsData([
      {
        icon: BPIcon,
        value: `${vitals?.bp?.systolic} / ${vitals?.bp?.diastolic}`,
        unit: "mmHg",
        label: "Blood Pressure",
      },
      {
        icon: PulseIcon,
        value: vitals?.pulse,
        unit: "BPM",
        label: "Pulse",
      },
      {
        icon: WeightIcon,
        value: vitals?.weight,
        unit: "kg",
        label: "Weight",
      },
      {
        icon: TempIcon,
        value: vitals?.temp,
        unit: "°F",
        label: "Temperature",
      },
      {
        icon: OxyIcon,
        value: vitals?.oxy,
        unit: "%",
        label: "Oxygen Level",
      },
    ]);
  }, [vitals]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-5">
      {vitalsData.map((vital, index) => (
        <VitalsCard
          key={index}
          icon={vital.icon}
          value={vital.value}
          unit={vital.unit}
        />
      ))}
    </div>
  );
}
