import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import ErrorScreen from "./screens/ErrorScreen";
import { SidebarProvider } from "./context/SidebarContext";
import { HospitalDataProvider } from "./context/HospitalContext";
import UnauthorisedPage from "./screens/UnauthorisedPage";
import UnderDevScreen from "./screens/UnderDevScreen";
import Dashboard from "./Dashboard/Dashboard";
import PatientTableScreen from "./PatientOverview/PatientTableScreen";
import Patient from "./Patient/Patient";
import Doctor from "./Doctor/Doctor";
import Staff from "./Staff/Staff";

export default function App() {
  return (
    <Router>
      <SidebarProvider>
        <HospitalDataProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/patients" exact element={<PatientTableScreen />} />
            <Route path="/clinics" exact element={<UnderDevScreen />} />
            <Route path="/appointments" exact element={<UnderDevScreen />} />
            <Route path="/patient/:id" exact element={<Patient />} />
            <Route path="/staff" exact element={<Staff />} />
            <Route path="/staff/:id" exact element={<Doctor />} />
            <Route path="/under-dev" element={<UnderDevScreen />} />
            <Route path="/unauthorized" element={<UnauthorisedPage />} />
            <Route path="/*" element={<ErrorScreen />} />
          </Routes>
        </HospitalDataProvider>
      </SidebarProvider>
    </Router>
  );
}
