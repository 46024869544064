import { LockOutlined } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function UnauthorisedPage() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div class="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-md text-center">
        <LockOutlined style={{ fontSize: 50 }} />
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          Unauthorized Access
        </h1>
        <p class="mt-4 text-muted-foreground">
          You do not have permission to access the requested content.
        </p>
        <button
          onClick={handleClick}
          className="bg-black p-2 pl-4 pr-4 text-white mt-6 rounded-lg"
        >
          Home Page
        </button>
      </div>
    </div>
  );
}
