import { ConstructionRounded } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomSideBar from "../components/CustomSideBar";

export default function UnderDevScreen() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="flex flex-col sm:flex-row min-h-screen">
      <CustomSideBar className="hidden sm:block" />
      <div className="sm:pl-72 items-center justify-center flex flex-grow sm:pb-10">
        <div class="mx-auto max-w-md text-center">
          <ConstructionRounded style={{ fontSize: 50 }} />
          <h1 class="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
            Under Development
          </h1>
          <p class="mt-4 text-muted-foreground">
            We're working hard to bring you something amazing. Please check back
            soon.
          </p>
          {/* <button
            onClick={handleClick}
            className="bg-black p-2 pl-4 pr-4 text-white mt-6 rounded-lg"
          >
            Home Page
          </button> */}
        </div>
      </div>
    </div>
  );
}
