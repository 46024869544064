import { CalendarToday, InfoOutlined } from "@mui/icons-material";
import React from "react";
import { Bar, BarChart, Line, LineChart, ResponsiveContainer } from "recharts";

const patientData = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const appointmentData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function PatientAndAppointmentCard({ cardType }) {
  return (
    <div className="px-5 py-6 rounded-xl flex flex-col gap-5 bg-white border border-borderGray h-full">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <p className="items-center">Patients</p>
          <InfoOutlined color="disabled" fontSize="small" />
        </div>
        <button className="border px-4 py-2 rounded-xl">See Details</button>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex border border-borderGray rounded-xl px-3 py-4">
          <CalendarToday style={{ height: "1rem" }} color="success" />
        </div>
        <div className="flex items-center gap-1">
          <p>1,898</p>
          <p>Hello</p>
        </div>
      </div>
      {/* Adjust the chart height */}
      <div className="flex items-center gap-2" style={{ height: "100px" }}>
        {cardType === "Patient" ? (
          <ResponsiveContainer width="35%" height="80%">
            <LineChart data={patientData}>
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#2F887C"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="35%" height="80%">
            <BarChart data={appointmentData}>
              <Bar dataKey="uv" fill="#2F887C" barSize={4} />
            </BarChart>
          </ResponsiveContainer>
        )}
        <p>{cardType === "Patient" ? "Patients" : "Appointments"} increased by 15%</p>
      </div>
    </div>
  );
}
