import React from "react";
import DetailSection from "./DetailSection";
import PatientScatterChart from "./PatientScatterChart";

export default function PatientScatterComponent() {
  return (
    <div className="rounded-xl p-4 bg-white flex flex-col gap-2 border border-borderGray h-full">
      <DetailSection title={"Patients"} />
      <p>
        Patient coming increased by <b>15%</b> in last 7 days
      </p>
      <PatientScatterChart />
    </div>
  );
}
