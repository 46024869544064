import { CloseRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Input from "../../CommonComponents/Input";
import MedicinePopupComponent from "../Components/MedicinePopupComponent";
import { getUserId } from "../../middleware/authUtils";
import { useParams } from "react-router-dom";
import DownloadPdfButton from "../../pdf/DownloadPdfButton";

export default function PrescriptionFormPopup({
  isOpen,
  onClose,
  metaData,
  presData = null,
  finalAudioBlob = null,
}) {
  const [formData, setFormData] = useState({
    diagnosis: "",
    history: "",
    name: "",
    age: "",
    sex: "",
    test_suggested: "",
    test_results: "",
    medical_notes: "",
    is_gemini_data: false,
    medication: [
      {
        medicine_name: "",
        dosage: "",
        days: "",
        is_sos: false,
        food: {
          before_breakfast: false,
          after_breakfast: false,
          lunch: false,
          dinner: false,
      },
        frequency: {
          od: false,
          bid: false,
          tid: false,
          qid: false,
          hs: false,
          ac: false,
          pc: false,
        },
      },
    ],
  });

  const [isPrescriptionEdited, setIsPrescriptionEdited] = useState(false);
  useEffect(() => {
    if (presData && typeof presData === "object") {
      setFormData({ ...presData, is_gemini_data: true });
    }
  }, [presData]);

  const patient = useParams();

  const processFormData = () => {
    try {
      const doctor = getUserId();
      return {
        ...formData,
        test_suggested:
          typeof formData?.test_suggested === "string" &&
          formData?.test_suggested.trim() !== ""
            ? formData?.test_suggested.split(",").map((test) => test.trim())
            : [],
        test_results:
          typeof formData?.test_results === "string" &&
          formData?.test_results.trim() !== ""
            ? formData?.test_results.split(",").map((test) => test.trim())
            : [],
        doctorId: doctor || null,
        patientId: patient?.id || null,
        medication: Array.isArray(formData?.medication)
          ? formData.medication.map((med) => {
              if (!med) return null;
              const allFrequencyFalse = med.frequency
                ? Object.values(med.frequency).every((val) => !val)
                : true;
              const allFoodFalse = med.food
                ? Object.values(med.food).every((val) => !val)
                : true;
  
              return {
                ...med,
                frequency: allFrequencyFalse ? null : med.frequency,
                food: allFoodFalse ? null : med.food,
              };
            }).filter((med) => med !== null) 
          : [],
      };
    } catch (error) {
      return null;
    }
  };
  
  const processedFormData = processFormData();

  const handleChange = (field, value, index = null) => {
    setIsPrescriptionEdited(true);
    // If updating the medication array
    if (field === "medication") {
      const updatedMedication = [...formData?.medication];
      updatedMedication[index] = {
        ...updatedMedication[index],
        ...value, // value should be an object with key-value pairs
      };
      setFormData({ ...formData, medication: updatedMedication });
    } else {
      // For other fields
      setFormData({ ...formData, [field]: value });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup content */}
      <div className="bg-white rounded-lg p-4 sm:p-6 z-10 w-full max-w-md sm:max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <p className="text-base sm:text-lg font-semibold">
            Create Prescription
          </p>
          <button className="text-red-600" onClick={onClose}>
            <CloseRounded />
          </button>
        </div>

        {/* Form Content */}
        <div className="flex flex-col gap-4">
          <Input
            title={"Diagnosis"}
            value={formData?.diagnosis}
            onChange={(e) => handleChange("diagnosis", e.target.value)}
          />
          <MedicinePopupComponent
            formData={formData}
            setFormData={setFormData}
            setIsEdited={setIsPrescriptionEdited}
          />

          <div className="rounded-lg border border-borderGray p-3 sm:p-4 flex flex-col gap-3">
            <p className="pb-2">Lab Tests</p>
            <textarea
              className="w-full border border-borderGray rounded-lg h-16 px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base"
              placeholder="Enter suggested tests here..."
              value={formData?.test_suggested ?? ""}
              onChange={(e) => handleChange("test_suggested", e.target.value)}
            />
            <textarea
              className="w-full border border-borderGray rounded-lg h-16 px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base"
              placeholder="Enter test results here..."
              value={formData?.test_results ?? ""}
              onChange={(e) => handleChange("test_results", e.target.value)}
            />
          </div>

          {/* Medical History Section */}
          <div>
            <p className="pb-2">Medical History</p>
            <textarea
              className="w-full border border-borderGray rounded-lg h-32 px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base"
              placeholder="Enter medical history here..."
              value={formData.history}
              onChange={(e) => handleChange("history", e.target.value)}
            />
          </div>

          {/* Medical Notes Section */}
          <div>
            <p className="pb-2">Notes</p>
            <textarea
              className="w-full border border-borderGray rounded-lg h-32 px-3 py-2 sm:px-4 sm:py-3 text-sm sm:text-base"
              placeholder="Enter notes here..."
              value={formData?.medical_notes ?? ""}
              onChange={(e) => handleChange("medical_notes", e.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <DownloadPdfButton
              patient={metaData}
              processedFormData={processedFormData}
              previewPrescription={true}
            />
            <DownloadPdfButton
              patient={metaData}
              processedFormData={processedFormData}
              previewPrescription={false}
              finalAudioBlob={finalAudioBlob}
              isPrescriptionEdited={isPrescriptionEdited}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
