import React from "react";
import Input from "../../CommonComponents/Input";

export default function DoctorDetails({ doctorData = {} }) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold">Doctor Details</p>
      </div>

      {/* General Details */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Input title={"Primary email"} value={doctorData.email} readOnly />
        <Input title={"Password"} value="******" type="password" readOnly />
        <div className="flex flex-col w-full gap-2">
          <p>Staff Type</p>
          <select
            className="p-3 border border-borderGray rounded-lg text-primaryGray w-full"
            disabled
            value={doctorData.staffType || ""}
          >
            <option value="admin">Admin</option>
            <option value="doctor">Doctor</option>
            <option value="nurse">Nurse</option>
          </select>
        </div>
      </div>

      {/* Basic Details */}
      <div className="flex flex-col gap-4 border border-borderGray rounded-lg p-4">
        <p>Basic Details</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input title={"Doctor name"} value={doctorData.name} readOnly />
          <Input title={"Age"} value={doctorData.age} type="number" readOnly />
          <Input
            title={"Date of birth"}
            value={doctorData.dob}
            type="date"
            readOnly
          />
          <div className="flex flex-col w-full gap-2">
            <p>Sex</p>
            <select
              className="p-3 border border-borderGray rounded-lg text-primaryGray w-full"
              disabled
              value={doctorData.sex || ""}
            >
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Others</option>
            </select>
          </div>
        </div>
      </div>

      {/* Contact Details */}
      <div className="flex flex-col gap-4 border border-borderGray rounded-lg p-4">
        <p>Contact details</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input
            title={"Alternative email"}
            value={doctorData.alternativeEmail}
            readOnly
          />
          <Input
            title={"Phone number"}
            value={doctorData.phoneNumber}
            readOnly
          />
          <Input title={"Address line 1"} value={doctorData.address} readOnly />
          <Input title={"Pincode"} value={doctorData.pincode} readOnly />
          <Input title={"City"} value={doctorData.city} readOnly />
          <Input title={"State"} value={doctorData.state} readOnly />
        </div>
      </div>

      {/* Career Details */}
      <div className="flex flex-col gap-4 border border-borderGray rounded-lg p-4">
        <p>Career details</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input
            title={"Qualification"}
            value={doctorData.qualification}
            readOnly
          />
          <Input title={"Degree"} value={doctorData.degree} readOnly />
          <Input
            title={"Experience"}
            value={doctorData.experience}
            type="number"
            readOnly
          />
          <Input
            title={"License Number"}
            value={doctorData.licenseNumber}
            readOnly
          />
          <Input title={"Language"} value={doctorData.language} readOnly />
        </div>
      </div>
    </div>
  );
}
