import { SearchRounded } from "@mui/icons-material";
import React from "react";

export default function SearchBar({ placeholderText, value, onChange }) {
  return (
    <div className="border border-borderGray py-2 px-3 rounded-xl flex items-center gap-2">
      <SearchRounded color="disabled" fontSize="medium" />
      <input
        onChange={onChange}
        value={value}
        placeholder={placeholderText}
        className="outline-none rounded-xl py-1 px-1 text-base leading-tight"
      ></input>
    </div>
  );
}
