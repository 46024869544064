import React, { useState } from "react";
import SidebarComponent from "./SidebarComponent";
import { useSidebar } from "../context/SidebarContext";
import { getUserRole } from "../middleware/authUtils";
import {
  CalendarToday,
  Dashboard,
  GroupOutlined,
  Logout,
  Menu,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import LogoHorizontal from "../logoHorizotal.svg";

const data = [
  [
    {
      title: "Dashboard",
      icon: Dashboard,
      path: "/dashboard",
      roles: ["admin", "doctor"],
      index: 1,
    },
    {
      title: "Appointments",
      icon: CalendarToday,
      highlightedIcon: "",
      path: "/appointments",
      roles: ["admin", "user"],
      index: 1,
    },
    {
      title: "Clinics",
      icon: CalendarToday,
      highlightedIcon: "",
      path: "/clinics",
      roles: ["admin", "doctor"],
      index: 1,
    },
    {
      title: "Patients",
      icon: GroupOutlined,
      path: "/patients",
      roles: ["admin", "user"],
      index: 1,
    },
    {
      title: "Staff",
      icon: GroupOutlined,
      path: "/staff",
      roles: ["admin"],
      index: 1,
    },
  ],
];

export default function CustomSideBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const sidebarData = useSidebar();

  const toShow = (title) => {
    const role = getUserRole();
    if (role?.includes("admin")) return true;

    switch (title) {
      case "Home":
        return true;
      case "Register Patient":
        return role?.includes("rec");
      case "Register Vitals":
        return role?.includes("nurse");
      case "Clinics":
        return role?.includes("admin") || role?.includes("doctor");
      case "Patients":
        return (
          role?.includes("doc") ||
          role?.includes("nurse") ||
          role?.includes("rec")
        );
      case "Dashboard":
        return true;
      case "Doctors":
        return role?.includes("rec");
      default:
        return false;
    }
  };

  return (
    <div>
      {/* Hamburger menu button */}
      <button
        className="sm:hidden fixed top-4 left-4 z-20 p-2 bg-white border border-gray-200 rounded-full shadow-md"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <Menu />
      </button>

      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 z-10 w-64 h-screen transition-transform bg-white border-r border-borderGray ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto flex flex-col">
          <div className="flex flex-col mt-auto mb-auto pb-10">
            {data?.map((sidebarItem, index) => {
              return (
                <ul
                  key={index}
                  className={
                    index === 0
                      ? `pt-4 mt-4 space-y-2 font-medium`
                      : `pt-4 mt-4 space-y-2 font-medium border-t border-slate-300`
                  }
                >
                  <li>
                    {sidebarItem.map((item, index) => {
                      const isActive = location.pathname === item.path;
                      return toShow(item.title) ? (
                        <SidebarComponent
                          key={index}
                          title={item?.title}
                          Icon={item?.icon}
                          path={item?.path}
                          isActive={isActive}
                        />
                      ) : null;
                    })}
                  </li>
                </ul>
              );
            })}
            <a
              className="flex items-center font-medium p-2 text-gray-900 rounded-lg hover:text-primaryGreen hover:bg-secondaryGreen group"
              href="/"
              onClick={() => {
                localStorage.removeItem("userToken");
              }}
            >
              <Logout className="hover:text-primaryGreen text-gray-400" />
              <span className="ms-3 font-sans">Logout</span>
            </a>
          </div>
          <img src={LogoHorizontal} className="w-3/4 mb-4 ml-4 " alt="Logo" />
        </div>
      </aside>

      {/* Overlay to close sidebar */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 sm:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </div>
  );
}
