import axios from "axios";
import React, { useEffect, useState, createContext } from "react";
import { getTokenData, isTokenValid } from "../middleware/authUtils";
import { useNavigate } from "react-router-dom";

export const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const navigate = useNavigate()
  const [sidebarData, setSidebarData] = useState(null);
  useEffect(() => {
    let tokenObject = localStorage.getItem("userToken");
    let token = JSON.parse(tokenObject)?.token;
    const tokenData = getTokenData();
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/sidebar/${tokenData?.role}`,
          // `${process.env.REACT_APP_BASE_URL}/dashboard/get`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        categorizeByIndex(response.data)
      } catch (err) {
        if(isTokenValid(err.response.code, err.response.statusText)) {
          navigate("/unauthorized")
          return
        }
        alert("Something went wrong")
      }
    };
    fetchDashboardData();
  }, []);

  const categorizeByIndex = (data) => {
    const categorizedData = [];

    data.forEach((item) => {
      const index = item.index;
      if (!categorizedData[index - 1]) {
        categorizedData[index - 1] = [];
      }
      categorizedData[index - 1].push(item);
    });
    setSidebarData(categorizedData);
    return categorizedData;
  };

  return (
    <SidebarContext.Provider value={sidebarData}>
      {children}
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => React.useContext(SidebarContext);
