import {
  AccessTimeRounded,
  CalendarToday,
  NavigateNext,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React from "react";
import { formatDate, formatTime } from "../../functions";

const getStatusStyles = (status) => {
  switch (status) {
    case "Confirmed":
      return "bg-secondaryGreen text-primaryGreen"; // Green for confirmed
    case "Pending":
      return "bg-yellow-100 text-yellow-700"; // Yellow for pending
    case "Cancelled":
      return "bg-red-50 text-red-700"; // Red for cancelled
    default:
      return "bg-gray-100 text-gray-700"; // Default style
  }
};

export default function AppointmentCard({ appointment, pageTitle }) {
  const statusStyles = getStatusStyles(appointment?.status);
  return (
    <div className="border border-borderGray p-4 rounded-xl gap-2 flex flex-col">
      <div className="flex items-center justify-between">
        <p className="font-base text-lg">
          {`Appointment with ${
            pageTitle === "patient" ? `Dr. ${appointment?.doctorName}` : ""
          }`}
        </p>
        <button className="px-2 py-1 text-sm rounded-lg bg-secondaryGreen text-primaryGreen">
          {appointment?.visitType}
        </button>
      </div>

      <div className="flex items-center gap-3 text-gray-500">
        {/* <button className={`px-2 text-xs py-1 rounded-lg ${statusStyles}`}>
          {appointment?.status}
        </button> */}
        <div className="flex text-sm items-center gap-1">
          <div className="flex items-center">
            <CalendarToday style={{ height: "1rem" }} />
            <p>{formatDate(appointment.startTime)}</p>
          </div>
          <div className="flex items-center">
            <AccessTimeRounded style={{ height: "1rem" }} />
            <p>{formatTime(appointment?.startTime) ?? "START_TIME"}</p>
            <NavigateNext style={{ height: "1rem" }} />
            <p>{formatTime(appointment?.endTime) ?? "END_TIME"}</p>
          </div>
        </div>
      </div>
      <p className="text-xs text-gray-600">
        {appointment?.notes}
      </p>
      {/* <div className="flex items-center gap-2">
        <Avatar
          src="https://s3-alpha-sig.figma.com/img/d589/9ef0/af12c047d27ea174791847f9f86f5f8d?Expires=1730678400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Q~Recl72q6YMETFOp-QeFwPwfwBer~k0F6SXxNTpAyhHea5HX8LN6kStwpHRvj8uSkIgLEVQVg8UHIBzU4FeQKkqQb5oKb6s9ohQKIzeWz6DIxFnTnPWTlRUKjyUj9N~pxfpxOYIva6AaPSMMqKpHSDbRkeu~5c9PZW9uT3OXhQLFDYb1V4gd6c-7ih2LWttNBlLoi0uWugWYAvo0sACmtli-vpzz0igSOzDT524WGcw8uc2Xo-3O179AZ2hHAR1ZIJgsfQbfu2B2m8YwXFP28R06G6H5hNBA4eBzxhc9J67b9UMpC7tzQDnfqQ~oPu0Nod2tbqP~etk--jSaQJCqg__"
          sizes="small"
          sx={{ width: 20, height: 20 }}
        />
        <p className="text-gray-600">{appointment?.doctor}</p>
      </div> */}
    </div>
  );
}
