import React, { useState, createContext, useContext, useEffect } from "react";

export const HospitalContext = createContext();

export function HospitalDataProvider({ children }) {
  const [hospitalData, setHospitalData] = useState(null);

  const updateHospitalData = (data) => {
    setHospitalData(data)
  };
  return (
    <HospitalContext.Provider value={{ hospitalData, updateHospitalData }}>
      {children}
    </HospitalContext.Provider>
  );
}

export const useHospital = () => {
  return useContext(HospitalContext);
};