import axios from "axios";

// authUtils.js
export const getTokenData = () => {
  const token = getTokenObj();
  if (!token) return null;

  try {
    // Assuming the token is a JWT
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload;
  } catch (error) {
    return null;
  }
};

export const fetchToken = () => {
  let tokenObject = localStorage.getItem("userToken");
  let token = JSON.parse(tokenObject)?.token;
  return token;
};

// export const isTokenValid = async () => {
//   return true
//   const token = fetchToken();
//   try {
//     const response = await axios.post(
//       `${process.env.REACT_APP_BASE_URL}/authenticate`,
//       // `${process.env.REACT_APP_BASE_URL}/hospital/authenticate`,
//       { token: token }
//     );
//     return true;
//   } catch (err) {
//     return false;
//   }
// };

export const isTokenValid = async (code, text) => {
  return code === 401 && text === "Unauthorized";
};

const getTokenObj = () => {
  const tokenStr = localStorage.getItem("userToken");
  const tokenObj = JSON.parse(tokenStr);
  return tokenObj?.token;
};

export const getUserRole = () => {
  const tokenData = getTokenData();
  return tokenData?.role;
};

export const getUserId = () => {
  const tokenData = getTokenData();
  return tokenData?.id;
};

export const getDoctorData = () => {
  const tokenData = getTokenData();
  return {
    id: tokenData?.id,
    name: tokenData?.name,
    email: tokenData?.email,
    phoneNumber: tokenData?.phone_number,
    qualification: tokenData?.qualification,
  };
};

export const hasRequiredRole = (requiredRole) => {
  return true;
  // const userRole = getUserRole();
  // const isPermitted = requiredRole.filter((role) => role === userRole);
  // return isPermitted.length;
};
