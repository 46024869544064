import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  CalendarToday,
} from "@mui/icons-material";
import React, { useState } from "react";
import PrescriptionTable from "./PrescriptionTable";
import PrescriptionVitals from "./PrescriptionVitals";
import { formatDate } from "../../functions";

export default function PrescriptionComponent({ prescriptions }) {
  const loadPrevPres = () => {
    if (rxIndex === 0) {
      alert("No Previous Prescriptions");
      return;
    }
    const newRxIndex = rxIndex - 1;
    setRxIndex(newRxIndex);
  };
  const loadNextPres = () => {
    if (rxIndex >= prescriptions.length - 1) {
      alert("No Next Prescriptions");
      return;
    }
    const newRxIndex = rxIndex + 1;
    setRxIndex(newRxIndex);
  };

  const [rxIndex, setRxIndex] = useState(0);
  return (
    <div className="flex flex-col gap-6 rounded-lg border border-borderGray px-5 py-6">
      <div className="flex items-center justify-between">
        <div className="flex flex-col text-lg font-medium gap-1">
          <p>Prescription {rxIndex+1}</p>
          <div className="flex items-center gap-2 text-primaryGray text-sm">
            <CalendarToday fontSize="small" />
            <p>{formatDate(prescriptions?.[rxIndex]?.created_at)}</p>
          </div>
        </div>

        <div className="flex gap-4">
          <button className="text-primaryGreen border border-borderGray p-2 rounded-lg flex items-center gap-2" onClick={loadPrevPres}>
            <ArrowBackIosNewRounded style={{ fontSize: "0.8rem" }} />
            Previous
          </button>
          <button className="text-primaryGreen border border-borderGray p-2 rounded-lg flex items-center gap-2" onClick={loadNextPres}>
            Next
            <ArrowForwardIosRounded style={{ fontSize: "0.8rem" }} />
          </button>
        </div>
      </div>
      <PrescriptionVitals vitals={prescriptions?.[rxIndex]?.vitals} />
      <PrescriptionTable prescription={prescriptions?.[rxIndex]} startDate={formatDate(prescriptions?.[rxIndex]?.created_at)} />
    </div>
  );
}
