import React from "react";
import DetailSection from "./DetailSection";
import AppointmentBarGraph from "./AppointmentBarGraph";

export default function AppointmentGraph() {
  return (
    <div className="rounded-xl p-4 bg-white gap-2 border border-borderGray">
      <DetailSection title={"Appointments"} />
      <AppointmentBarGraph />
    </div>
  );
}
