import React from "react";

export default function Input({
  title,
  name,
  value,
  onChange,
  placeholder = "Type input here...",
  type = "text",
  isRequired = true,
  multiple = false,
  readOnly = false,
  minLength = null,
  maxLength = null,
  maxValue = null,
  minValue = null
}) {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-sm font-light text-secondaryText">{title}</p>
      <input
        value={value}
        name={name}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        max={maxValue}
        min={minValue}
        required={isRequired}
        className="rounded-xl border border-borderGray p-3 w-full placeholder:font-light"
        placeholder={placeholder}
        type={type}
        multiple={multiple}
        readOnly={readOnly}
      />
    </div>
  );
}
