import axios from "axios";
import "../App.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../Network/login.network";
import { CircularProgress } from "@mui/material";
import { fetchToken } from "../middleware/authUtils";
import LogoVerical from "../logoVertical.svg";

function Login() {
  const navigate = useNavigate();
  const [userCred, setUserCred] = useState({ email: "", password: "" });
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserCred((prevCred) => ({
      ...prevCred,
      [name]: value,
    }));
  };

  const checkLoggedIn = async () => {
    const tokenObj = fetchToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/authenticate`,
        { token: tokenObj }
      );
      navigate("/dashboard");
    } catch {}
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  const handleSubmit = async (event) => {
    setShowLoader(true);
    event.preventDefault();
    const response = await loginUser(userCred);
    if (response.statusCode === 201) {
      localStorage.setItem(
        "userToken",
        JSON.stringify({ token: response?.data?.access_token })
      );
      navigate("/dashboard");
    } else if (response.statusCode === 401) {
      alert("Invalid Credentials");
    } else {
      alert("Something went wrong!");
    }
    setShowLoader(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
      <img src={LogoVerical} className="w-24 mb-6 sm:w-40 lg:w-36" alt="Logo" />
      <form
        onSubmit={handleSubmit}
        className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg"
      >
        <div className="mb-4">
          <input
            className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring focus:border-secondaryGreen"
            placeholder="Email"
            type="email"
            name="email"
            required={true}
            value={userCred.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <input
            className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring focus:border-secondaryGreen"
            placeholder="Password"
            type="password"
            name="password"
            required={true}
            value={userCred.password}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-primaryGreen text-white py-2 rounded-lg flex justify-center"
        >
          {showLoader ? `` : `Login`}
          {showLoader && (
            <CircularProgress size={20} style={{ color: `white` }} />
          )}
        </button>
        <div className="flex mt-4 text-sm">
          <p className="text-neutral-600 mr-1">New Here?</p>
          <Link
            to="/signup"
            className="text-neutral-600 underline hover:text-primaryGreen"
          >
            Signup
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
