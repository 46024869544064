import React from "react";

export default function OutlinedButton({
  IconFirst,
  iconFirstColor,
  text,
  textColor,
  IconLast,
  iconLastColor,
  btnClicked,
  borderColor = "borderGray",
}) {
  return (
    <button
      onClick={btnClicked}
      className={`p-3 px-4 whitespace-nowrap w-full border border-${borderColor} rounded-lg flex items-center justify-center gap-2 font-medium text-sm ${textColor}`}
    >
      {IconFirst && (
        <IconFirst style={{ fontSize: "1rem" }} htmlColor={iconFirstColor} />
      )}
      <p className="leading-none">{text}</p>
      {IconLast && (
        <IconLast style={{ fontSize: "1rem" }} htmlColor={iconLastColor} />
      )}
    </button>
  );
}
