import { Mic, PictureAsPdf } from "@mui/icons-material";
import React from "react";

export default function FilesAndDocs({ data }) {
  return (
    <div className="rounded-lg border border-borderGray px-5 py-6 gap-6 flex flex-col">
      <div className="flex justify-between items-center">
        <p>Files & Documents</p>
      </div>
      {data?.length !== 0 ? data?.map((file, index) => {
        return (
          <div key={index}>
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div clasesName="flex gap-2">
                  <PictureAsPdf className="text-primaryGreen" />
                  <p className="text-primaryGray">{file?.presUrl}</p>
                </div>
                <button className="text-sky-600 underline text-sm">View</button>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div clasesName="flex gap-2">
                  <Mic className="text-primaryGreen" />
                  <p className="text-primaryGray">{file?.audioUrl}</p>
                </div>
                <button className="text-sky-600 underline text-sm">View</button>
              </div>
            </div>
          </div>
        );
      }) : <p className="text-center text-primaryGray">No Documents Found</p>}
    </div>
  );
}
