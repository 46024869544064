import { CloseRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Input from "../../CommonComponents/Input";
import FilledButton from "../../CommonComponents/FilledButton";
import { editMetaData } from "../../Network/prescription.network";

export default function EditMetaDataPopup({
  isOpen,
  onClose,
  metaData,
  patientId,
  setMetaData,
}) {
  const [formData, setFormData] = useState({
    height: "",
    sex: "",
    dob: "",
  });
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  // Update form data when metaData changes
  useEffect(() => {
    if (metaData) {
      setFormData({
        height: metaData?.height,
        sex: metaData?.sex,
        dob: metaData?.dob,
      });
    }
  }, [metaData]); // This runs whenever metaData changes

  // Handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setIsBtnClicked(true)
    const response = await editMetaData(formData, patientId);
    if (response.statusCode === 200) {
      setMetaData({ ...metaData, ...formData });
      onClose(); // Close the popup after saving
    }
    setIsBtnClicked(false)
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Popup content with padding from screen edges */}
      <div className="bg-white rounded-lg p-6 z-10 max-w-lg w-full mx-4 md:mx-8">
        <div className="flex justify-between items-center mb-4">
          <p className="text-lg font-semibold">Edit Metadata</p>
          <button className="text-red-600" onClick={onClose}>
            <CloseRounded />
          </button>
        </div>
        <div className="flex flex-col gap-4">
          <Input
            title="Height (cm) *"
            value={formData.height}
            name="height"
            onChange={handleChange}
          />
          <div className="flex flex-col w-full gap-2">
            <p className="text-secondaryText font-light text-sm">Gender *</p>
            <select
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              className="p-3 border border-borderGray rounded-lg font-light w-full"
            >
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>
          <Input
            title="Date of Birth *"
            type="date"
            value={formData.dob}
            name="dob"
            onChange={handleChange}
          />
        </div>
        <div className="w-full pt-4">
          <FilledButton
            text={"Save & Update"}
            btnClicked={handleSave}
            showLoader={isBtnClicked}
          />
        </div>
      </div>
    </div>
  );
}
