import React from "react";
import { ErrorScreenMessages } from "../constants/stringConstants";
import { ReportProblemRounded } from "@mui/icons-material";

export default function ErrorScreen() {
  return (
    <div class="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-md text-center">
        <ReportProblemRounded style={{ fontSize: 50 }} />
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
          {ErrorScreenMessages.title}
        </h1>
        <p class="mt-4 text-muted-foreground">{ErrorScreenMessages.message}</p>
        <div class="mt-6">
          <a
            class="inline-flex text-slate-100 bg-black items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            href="/"
            rel="ugc"
          >
            {ErrorScreenMessages.btnText}
          </a>
        </div>
      </div>
    </div>
  );
}
