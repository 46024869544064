import { getUserId } from "../middleware/authUtils";
import { apiClient } from "./api.client";

export const postAndComprehendPrescription = async (audioBlob, patient) => {
  const doctor = getUserId();
  try {
    const formData = new FormData();
    formData.append("file", audioBlob, "recorded-audio.mp3");
    formData.append("doctor", doctor);
    formData.append("patient", patient);

    const response = await apiClient.post(
      `/prescription/comprehend`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    await logError(error.message, error.stack, doctor, patient);
    return { status: "error", message: error.message, statusCode: error.code };
  }
};

export const uploadFiles = async (audioBlob, pdfBlob, patient) => {
  const doctor = getUserId();
  const time = Date.now();
  try {
    const formData = new FormData();
    if (audioBlob) {
      formData.append("files", audioBlob, `${time}_final_audio_file.mp3`);
    }
    if (pdfBlob) {
      formData.append("files", pdfBlob, `${time}_generated_prescription.pdf`);
    }
    formData.append("doctor", doctor);
    formData.append("patient", patient);

    const response = await apiClient.post(`/media/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    console.error("Error posting and comprehending prescription:", error);
    return { status: "error", message: error.message, statusCode: error.code };
  }
};

export const postPrescription = async (prescriptionDetails) => {
  try {
    const response = await apiClient.post(
      `/prescriptions/create`,
      prescriptionDetails
    );
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message, statusCode: error.code };
  }
};

export const fetchAllPrescriptionOfPatient = async (req, res) => {
  const { id } = req.params; // Assuming patient ID is passed as a URL parameter
  try {
    const response = await apiClient.get(`/prescriptions/patient/${id}`);
    return response.data; // Return the response data from the API
  } catch (error) {
    console.error("Error fetching prescriptions for patient:", error);
    return { status: "error", message: error.message };
  }
};

export const postVitals = async (vitalsData) => {
  try {
    const response = await apiClient.post(`/vitals`, vitalsData);
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const editMetaData = async (updatedData, patinetId) => {
  updatedData = {
    ...updatedData,
    dob: new Date(updatedData.dob).toISOString(),
  };
  try {
    const response = await apiClient.put(`meta-data/${patinetId}`, updatedData);
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const fetchAllClinicsOfAdmin = async (adminId) => {
  try {
    const response = await apiClient.get(`admin/${adminId}`);
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const fetchAllClinicsOfDoctor = async (doctorId) => {
  try {
    const response = await apiClient.get(`doctor/${doctorId}`);
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const fetchPatientsOfClinic = async (clinicId) => {
  try {
    const response = await apiClient.getO(`patients/clinic/${clinicId}`);
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const logError = async (error_message, stack_trace, doctor, patient) => {
  try {
    const response = await apiClient.post(`/error`, {
      error_message,
      stack_trace,
      doctor,
      patient,
    });
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};

export const addNewPatient = async (patientData, contactData, metaData) => {
  const doctorId = getUserId();
  try {
    const response = await apiClient.post(`/patients/add/${doctorId}`, {
      patient: patientData,
      contact: contactData,
      metaData: metaData
    });
    return {
      data: response.data,
      statusCode: response.status,
      message: "success",
    };
  } catch (error) {
    return { status: "error", message: error.message };
  }
};