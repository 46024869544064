import { EditRounded } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { findAge } from "../../functions";

export default function PatientOverview({ metaData, setIsMetaDataPopup }) {
  const [patientOverview, setPatientOverview] = useState(null);

  useEffect(() => {
    setPatientOverview([
      { title: "Full Name", desc: metaData?.name || "N/A" },
      { title: "Age", desc: metaData?.dob ? `${findAge(metaData.dob)} Y` : "" },
      { title: "Height", desc: metaData?.height ? `${metaData.height} cm` : "" },
      { title: "Sex", desc: metaData?.sex ? `${metaData.sex === "MALE" ? "M" : "F"}` : "" },
    ]);
  }, [metaData]);

  return (
    <div className="rounded-lg bg-primaryGreen text-white p-5 sm:p-8 md:flex items-center justify-between">
      <div className="flex flex-col sm:flex-row items-center gap-6 md:gap-10">
        <Avatar className="border-2 border-white w-16 h-16 md:w-20 md:h-20" />
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6">
          {patientOverview?.map((detail, index) => (
            <div
              className="flex flex-col items-center sm:items-start gap-1"
              key={index}
            >
              <p className="text-sm font-light">{detail?.title}</p>
              <p className="text-lg font-medium">{detail?.desc}</p>
            </div>
          ))}
        </div>
      </div>
      <button onClick={setIsMetaDataPopup}>
        <EditRounded
          className="border-2 border-white rounded-full p-1 mt-4 md:mt-0 md:ml-4 cursor-pointer transition transform hover:scale-105"
          fontSize="large"
        />
      </button>
    </div>
  );
}
