import React from "react";
import AppointmentCard from "./AppointmentCard";
import { InfoOutlined } from "@mui/icons-material";

export default function Appointment({pageTitle, data}) {

  return (
    <div className="px-5 py-6 rounded-xl flex flex-col gap-5 bg-white border border-borderGray h-full">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <p className="items-center">{pageTitle === `Dashboard` ? `Todays Appointment` : `Recent Visits`}</p>
          <InfoOutlined color="disabled" fontSize="small" />
        </div>

        <button className="border px-4 py-2 rounded-xl">See Details</button>
      </div>
      {data?.map((appointment, index) => {
        return <AppointmentCard appointment={appointment} pageTitle={pageTitle} key={index} />;
      })}
    </div>
  );
}
