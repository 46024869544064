import React from "react";

export default function Button({ StartIcon, text, textColor, EndIcon, onClick }) {
  return (
    <button className="flex gap-1 items-center" onClick={onClick}>
      {StartIcon && <StartIcon className={`${textColor}`} />}
      <p className={`${textColor} text-lg`}>{text}</p>
      {EndIcon && <EndIcon className={`${textColor}`} />}
    </button>
  );
}
