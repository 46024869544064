import { CloseRounded } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import recordCircle from "./../Components/record-circle.svg";
import { postAndComprehendPrescription } from "../../Network/prescription.network";
import { CircularProgress } from "@mui/material";

export default function RecordPopup({
  isOpen,
  setIsPrescFormOpen,
  onClose,
  setPrescriptionResponse,
  setFinalAudioBlob
}) {
  const mimeType = "audio/mp4";
  const mediaRecorder = useRef(null);
  const audioChunksRef = useRef([]);
  const [stream, setStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingComplete, setIsRecordingComplete] = useState(false);
  const timerRef = useRef(null); // Timer reference
  const [elapsedTime, setElapsedTime] = useState(0); // Timer state
  const [audioBlob, setAudioBlob] = useState(null); // New state for the audio blob
  const [isComprehendClicked, setIsComprehendClicked] = useState(false)

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setStream(streamData);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    if (!stream) {
      alert("Stream is not available. Please check microphone permissions.");
      return;
    }
  
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    audioChunksRef.current = [];
  
    mediaRecorder.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };
  
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
      setAudioBlob(audioBlob); // Save the blob in state
    };
  
    mediaRecorder.current.start();
    setElapsedTime(0);
    timerRef.current = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);
  
    setIsRecording(true);
    setIsRecordingComplete(false);
  };

  const stopRecording = () => {
    setIsRecording(false);
    setIsRecordingComplete(true);
    if (mediaRecorder.current) {
      mediaRecorder.current.stop(); // Stop recording
    } else {
      console.error("Media recorder is not initialized");
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop()); // Stop accessing the microphone
    }
    // Stop the timer
    clearInterval(timerRef.current);
  };

  const closeRecordPopup = () => {
    stopRecording()
    setIsRecordingComplete(false)
    setIsRecording(false)
    setFinalAudioBlob(null)
    setPrescriptionResponse(null)
    onClose()
  }

  const comprehendPres = async () => {
    if (!audioBlob) {
      alert("No audio file available.");
      return;
    }
    
    setIsComprehendClicked(true)
    const pateintId = window.location.pathname.split("/")[2]
    const response = await postAndComprehendPrescription(audioBlob, pateintId); // Send blob to function
    setIsRecordingComplete(false);
    if (response?.message === "success") {
      setFinalAudioBlob(audioBlob)
      setPrescriptionResponse({...response.data, is_edited: false});
      onClose();
      setIsPrescFormOpen(true);
    } else {
      alert("Some Error Occurred");
    }
    setIsComprehendClicked(false)
  };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={closeRecordPopup}
      ></div>

      {/* Popup content */}
      <div className="bg-white rounded-lg p-6 z-10 max-w-lg w-full flex flex-col gap-2">
        <div className="flex justify-between items-center mb-4">
          <p className="text-lg font-semibold">Record Prescription</p>
          <button className="text-primaryRed" onClick={closeRecordPopup}>
            <CloseRounded />
          </button>
        </div>

        <img
          src={recordCircle}
          alt="Record icon"
          className="mx-auto rounded-full p-5 bg-secondaryGreen"
        />

        {/* Recording Timer */}
        {isRecording && (
          <div className="rounded-lg p-1.5 text-sm text-primaryRed bg-secondaryRed text-center mx-auto px-2">
            <p>{formatTime(elapsedTime)}</p>
          </div>
        )}

        {/* Control Buttons */}
        {!isRecordingComplete ? (
          <button
            className="rounded-lg p-2 text-primaryGreen border border-borderGray w-full"
            onClick={() => {
              if (isRecording) {
                stopRecording();
              } else {
                getMicrophonePermission();
                startRecording();
              }
            }}
          >
            {isRecording ? "STOP" : "START"}
          </button>
        ) : (
          <button
            className="rounded-lg p-2 text-primaryGreen border border-borderGray w-full"
            disabled={isComprehendClicked}
            onClick={() => {
              comprehendPres();
            }}
          >
            {isComprehendClicked ? ``: `COMPREHEND`}
            {isComprehendClicked && <CircularProgress size={20} style={{ color: "#2F887C" }} />}
          </button>
        )}
      </div>
    </div>
  );
}
