import { CloseRounded } from "@mui/icons-material";
import React, { useState } from "react";
import Input from "../../CommonComponents/Input";
import FilledButton from "../../CommonComponents/FilledButton";
import { addNewPatient, postVitals } from "../../Network/prescription.network";
import { getDoctorData } from "../../middleware/authUtils";

export default function AddPatientPopup({ isOpen, onClose }) {
  const emtpyStateData = {
    userData: { name: "" },
    metaData: { dob: "", sex: "", height: "" },
    contactData: {
      phone_number: "",
      line1: "",
      line2: "",
      pincode: "",
      country_code: "+91",
    },
    vitals: {
      weight: "",
      temp: "",
      oxy: "",
      bp: { systolic: "", diastolic: "" },
      pulse: "",
    },
  };

  const [state, setState] = useState(emtpyStateData);
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  const handleChange = (section) => (e) => {
    const { name, value } = e.target;
    const parsedValue = isNaN(value) || value === "" ? "" : Number(value);

    if (section === "vitals" && isNaN(Number(value))) {
      alert("Please enter number!");
      return;
    }
    setState((prevState) => {
      try {
        if (section === "vitals" && ["systolic", "diastolic"].includes(name)) {
          return {
            ...prevState,
            [section]: {
              ...prevState[section],
              bp: {
                ...prevState[section].bp,
                [name]: parsedValue,
              },
            },
          };
        }

        if (section === "vitals") {
          return {
            ...prevState,
            [section]: {
              ...prevState[section],
              [name]: parsedValue,
            },
          };
        }

        return {
          ...prevState,
          [section]: {
            ...prevState[section],
            [name]: value,
          },
        };
      } catch (error) {
        console.error("Error updating state:", error);
        return prevState; // Revert to the previous state on error
      }
    });
  };

  const addPatient = async () => {
    if (contactData.phone_number.length !== 10) {
      alert("Please enter valid phone number!");
      return;
    }
    setIsBtnClicked(true);
    try {
      const response = await addNewPatient(userData, contactData, metaData);
      const updatedVitalsData = { ...vitals, userId: response.data?.id };
      await postVitals(updatedVitalsData);
      setState(emtpyStateData);
      onClose();
    } catch (error) {
      alert("Something Went Wrong. Please try again later!")
      console.error("Error adding patient:", error);
    } finally {
      setIsBtnClicked(false);
    }
    setIsBtnClicked(false);
  };

  const { userData, metaData, contactData, vitals } = state;
  const doctor = getDoctorData();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      <div className="bg-white rounded-lg p-4 md:p-6 z-10 w-full max-w-xs sm:max-w-md lg:max-w-2xl flex flex-col gap-4 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-2 md:mb-4">
          <p className="text-base md:text-lg font-semibold">Add Patient</p>
          <button className="text-primaryRed" onClick={onClose}>
            <CloseRounded />
          </button>
        </div>
        <form className="flex flex-col gap-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Input
              title={"Patient Name *"}
              name="name"
              value={userData.name}
              onChange={handleChange("userData")}
            />
            <Input
              title={"Patient Phone *"}
              name="phone_number"
              maxLength={10}
              minLength={10}
              value={contactData.phone_number}
              onChange={handleChange("contactData")}
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <Input
              title={"Date of Birth *"}
              name="dob"
              type="date"
              value={metaData.dob}
              maxValue={new Date().toISOString().split("T")[0]}
              onChange={handleChange("metaData")}
            />
            <div className="flex flex-col w-full gap-2">
              <p className="text-secondaryText font-light text-sm">Gender *</p>
              <select
                name="sex"
                value={metaData.sex}
                onChange={handleChange("metaData")}
                className="p-3 border border-borderGray rounded-lg font-light w-full"
              >
                <option value="" disabled>
                  Choose Gender
                </option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
            </div>
            <Input
              title={"Height (in cm) *"}
              name="height"
              value={metaData.height}
              onChange={handleChange("metaData")}
            />
          </div>
          <Input
            title={"Address Line 1 *"}
            name="line1"
            value={contactData.line1}
            onChange={handleChange("contactData")}
          />
          <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-4">
            <Input
              title={"Address Line 2"}
              isRequired={false}
              name="line2"
              value={contactData.line2}
              onChange={handleChange("contactData")}
            />
            <Input
              title={"Pincode *"}
              name="pincode"
              maxLength={6}
              minLength={6}
              value={contactData.pincode}
              onChange={handleChange("contactData")}
            />
          </div>
          <div className="flex flex-col w-full gap-2">
            <p className="text-secondaryText font-light text-sm">
              Reporting Doctor *
            </p>
            <select
              name="doctor"
              value={doctor.name ?? "Loading"}
              disabled
              className="p-3 border border-borderGray rounded-lg font-light text-secondaryText w-full"
            >
              <option>Dr. {doctor.name}</option>
            </select>
          </div>

          {/* Vitals Section */}
          <div className="rounded-xl border border-primaryGreen bg-secondaryGreen p-4 space-y-4">
            <p className="text-primaryGreen">Add Vitals</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Input
                title={"Weight (KG) *"}
                name="weight"
                value={vitals.weight}
                onChange={handleChange("vitals")}
              />
              <Input
                title={"Temperature *"}
                name="temp"
                value={vitals.temp}
                onChange={handleChange("vitals")}
              />
              <Input
                title={"Systolic *"}
                name="systolic"
                value={vitals.bp.systolic}
                onChange={handleChange("vitals")}
              />
              <Input
                title={"Diastolic *"}
                name="diastolic"
                value={vitals.bp.diastolic}
                onChange={handleChange("vitals")}
              />

              <Input
                title={"Pulse (BPM) *"}
                name="pulse"
                value={vitals.pulse}
                onChange={handleChange("vitals")}
              />
              <Input
                title={"O2 Saturation (%) *"}
                name="oxy"
                value={vitals.oxy}
                onChange={handleChange("vitals")}
              />
            </div>
          </div>
          <FilledButton
            btnType={"submit"}
            text={"Add"}
            className="self-center sm:self-end"
            btnClicked={addPatient}
            showLoader={isBtnClicked}
          />
        </form>
      </div>
    </div>
  );
}
