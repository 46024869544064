import React from "react";
import DosageCheckbox from "./DosageCheckbox";
import { formatDate } from "../../functions";

const tableHeaders = [
  "Diagnosis",
  "Lab Tests",
  "Medication",
  "Dosage",
  "Start Date",
  "End Date",
];

export default function PrescriptionTable({ prescription, startDate }) {
  return (
    <div className="rounded-xl border overflow-x-auto">
      <table className="min-w-full text-sm text-left">
        <thead className="text-xs text-gray-700 uppercase bg-gray-200">
          <tr>
            {tableHeaders?.map((header, index) => (
              <th key={index} scope="col" className="px-6 py-3">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {prescription?.medication?.map((medication, index) => {
            const endDate = medication?.days
              ? formatDate(startDate, medication?.days)
              : "NaN";
            return (
              <tr key={index} className="bg-white border-b">
                <td
                  scope="row"
                  className="px-6 py-4 text-gray-900 whitespace-nowrap"
                >
                  {prescription?.diagnosis ?? "NaN"}
                </td>
                <td className="px-6 py-4">
                  {prescription?.test_suggested?.length > 0
                    ? prescription.test_suggested.join(", ")
                    : "NaN"}
                </td>
                <td className="px-6 py-4">
                  {medication?.medicine_name ?? "NaN"}
                </td>
                <td>
                  {medication?.is_sos ? (
                    <div className="items-center flex ml-6 gap-1">
                      <p className="uppercase px-2 py-1 rounded-lg  bg-primaryRed text-primaryRed bg-opacity-5">
                        SOS
                      </p>
                    </div>
                  ) : (
                    <DosageCheckbox
                      food={medication?.food}
                      frequency={medication?.frequency}
                    />
                  )}
                </td>
                <td className="px-6 py-4">{startDate}</td>
                <td className="px-6 py-4">{endDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
