import React, { useMemo } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { findAge } from "../functions";
import { getDoctorData } from "../middleware/authUtils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#F0F0F0",
    padding: 40,
  },
  header: {
    marginBottom: 25,
  },
  doctorDetails: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#000",
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 4,
  },
  sectionHeader: {
    marginTop: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    fontWeight: "medium",
  },
  patientDetails: {
    fontSize: 12,
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "25%",
    fontSize: 12,
    backgroundColor: "#D3D3D3",
    padding: 5,
    border: "0.5px solid black",
  },
  tableCol: {
    width: "25%",
    padding: 5,
    fontSize: 12,
    border: "0.5px solid black",
  },
  notesSection: {
    marginTop: 20,
    padding: 10,
    border: "1px solid #000",
    fontSize: 12,
  },
  footer: {
    position: "absolute",
    bottom: 10, // Position 10 units from the bottom
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const frequencyLabels = {
  od: "Once Daily",
  bid: "Twice Daily",
  tid: "Three Times Daily",
  qid: "Four Times Daily",
  hs: "At Bedtime",
  ac: "Before Meals",
  pc: "After Meals",
};

const setFrequency = (frequency, food, is_sos) => {
  if (is_sos) return "SOS";

  // Check the food object first
  if (food && typeof food === "object") {
    const foodTimings = [
      food.before_breakfast ? 1 : 0,
      food.after_breakfast ? 1 : 0,
      food.lunch ? 1 : 0,
      food.dinner ? 1 : 0,
    ];
    if (foodTimings.some((timing) => timing === 1)) {
      return foodTimings.join("-");
    }
  }

  // Check the frequency object
  if (frequency && typeof frequency === "object") {
    const activeKey = Object.keys(frequency).find((key) => frequency[key]);
    if (activeKey) {
      return frequencyLabels[activeKey]; // Return the label for the active key
    }
  }

  // Default fallback
  return "";
};

const PrescriptionPdf = ({
  diagnosis,
  patient,
  medications = [],
  test_suggested,
  test_reports,
  notes,
}) => {
  const doctor = useMemo(() => getDoctorData(), []);
  const refactorQualification = () => {
    const qualificationDetails = doctor.qualification;
    return qualificationDetails
      ?.map((el) => el?.qualification)
      .filter((qual) => qual)
      .join(", ");
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Doctor's Letterhead */}
        <View style={styles.header}>
          <Text style={styles.doctorDetails}>{doctor?.name}</Text>
          <Text style={styles.doctorDetails}>{refactorQualification()}</Text>
          <Text style={styles.doctorDetails}>{doctor?.phoneNumber}</Text>
          <Text style={styles.doctorDetails}>{doctor?.email}</Text>
        </View>
        <View
          style={{
            marginBottom: 5,
            width: "100%",
            borderBottomColor: "black",
            borderBottomWidth: 1,
            alignSelf: "center",
          }}
        />

        {/* Compact Patient Details in 2 lines */}
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>Patient Details:</Text>
          <View>
            <View style={styles.row}>
              <Text style={styles.text}>Name: {patient?.name ?? ""}</Text>
              <Text style={styles.text}>
                Age: {`${findAge(patient?.dob)} years` ?? ""}
              </Text>
              <Text style={styles.text}>Sex: {patient?.sex ?? ""}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.text}>Height: {patient?.height ?? ""}</Text>
              <Text style={styles.text}>
                Weight: {`${patient?.vitals?.weight} kg` ?? ""}
              </Text>
              <Text style={styles.text}>
                BP:{" "}
                {`${patient?.vitals?.bp?.systolic} / ${patient?.vitals?.bp?.diastolic} mm/Hg` ??
                  ""}
              </Text>
              <Text style={styles.text}>
                Pulse: {`${patient?.vitals?.pulse} bpm` ?? ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>Diagnosis:</Text>
          <Text style={styles.patientDetails}>{diagnosis}</Text>
        </View>

        {/* Prescription Table */}
        <View style={styles.sectionHeader}>
          <Text style={styles.sectionTitle}>Prescription:</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Medicine Name</Text>
              {/* <Text style={styles.tableColHeader}>Dosage</Text> */}
              <Text style={styles.tableColHeader}>Frequency</Text>
              <Text style={styles.tableColHeader}>Days</Text>
              {/* <Text style={styles.tableColHeader}>SOS</Text> */}
            </View>
            {(medications.length > 0 ? medications : [{}]).map((med, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCol}>
                  {med.medicine_name} {med.dosage}
                </Text>
                {/* <Text style={styles.tableCol}>{med.dosage}</Text> */}
                <Text style={styles.tableCol}>
                  {setFrequency(med.frequency, med.food, med.is_sos)}
                </Text>
                <Text style={styles.tableCol}>{med.days}</Text>
                {/* <Text style={styles.tableCol}>{med.is_sos ? "Yes" : "No"}</Text> */}
              </View>
            ))}
          </View>
        </View>

        {/* Lab Tests */}
        {test_suggested != undefined && test_suggested.length > 0 && (
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>Lab Tests Suggested:</Text>

            {test_suggested.map((test, index) => (
              <Text key={index} style={styles.patientDetails}>
                - {test}
              </Text>
            ))}
          </View>
        )}
        {test_reports != undefined && test_reports.length > 0 && (
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>Lab Test Reports:</Text>
            {test_reports.map((test, index) => (
              <Text key={index} style={styles.patientDetails}>
                - {test}
              </Text>
            ))}
          </View>
        )}

        {/* Notes Section */}
        {notes && (
          <View style={styles.notesSection}>
            <Text style={styles.sectionTitle}>Doctor's Notes:</Text>
            <Text>{notes}</Text>
          </View>
        )}
        <View style={styles.footer}>
          <View
            style={{
              marginBottom: 5,
              width: "100%",
              borderBottomColor: "black",
              borderBottomWidth: 1,
              alignSelf: "center",
            }}
          />
          <Text style={{ textAlign: "center", fontSize: 12 }}>
            Not for medico legal purposes.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PrescriptionPdf;
